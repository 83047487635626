import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Auth } from '@aws-amplify/auth/';
import { AuthService } from '@app/components/auth/auth.service';

@Component({
  selector: 'app-password-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'enterYourPassword' | translate}}</h4>
    <button type="button" class="close" aria-label="Close button" 
      aria-describedby="modal-title" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #form="ngForm">  
    <div class="modal-body">
      <input class="form-control" ngbAutofocus type="password" name="password" required  pattern=".{8,}" id="password" 
      [(ngModel)]="passValue" placeholder="{{'enterYourPassword'|translate}}">
    </div>
    <div class="container-sm">
      <div class="row">
        <div class="col">
          <button type="submit" id="btnOk" class="btn btn-block btn-primary btn-sm" (click)="verifyPasswordBySignIn()" 
          [disabled]="form.invalid">{{'ok'|translate}}</button>
        </div>        
        <div class="col">    
          <button type="button" id="btnCancel" class="btn btn-block btn-secondary btn-sm" 
          (click)="close()">{{'cancel'|translate}}</button>         
        </div>  
      </div>
    </div>
        <p></p>
        <p></p>
  </form>
  `
})

@Injectable({
  providedIn: 'root'
})

export class AppPasswordConfirmComponent {
  passValue = '';

  constructor(
    public modal: NgbActiveModal,
    private authService: AuthService) {
  }

  verifyPasswordBySignIn(): any {
    Auth.signIn(this.authService.getUserName(), this.passValue).then(user => {
      this.modal.close('ok');
    }).catch(err => {
      alert(err.message);
    });
  }

  close(): any {
    this.modal.dismiss('done');
  }
}

const MODALS: { [name: string]: any } = {
  passwordConfirm: AppPasswordConfirmComponent
};


@Component({
  selector: 'app-password-verification',
  templateUrl: './password-verification.component.html'
})

@Injectable({
  providedIn: 'root'
})

export class PasswordVerificationComponent implements OnInit {

  constructor(
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  open(): any {
    const promise = new Promise((resolve, reject) => {
      this.modalService.open(MODALS.passwordConfirm, { centered: true, size: 'sm' }).result.then((result) => {
        resolve(result);
      }, (dismiss) => {
        reject(dismiss);
      });
    });
    return promise;
  }
}
