import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Merchant, MyCurrency } from '@app/shared/yubilly-data';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { YubillyService } from '@app/shared/yubilly.service';
import { PlatformLocation } from '@angular/common';
import { ModalConfigComponent } from '../modal-config/modal-config.component';
import { ModalConfirmationService } from '../modal-confirmation/modal-confirmation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-merchants',
  templateUrl: './merchants.component.html',
  styleUrls: ['./merchants.component.css']
})
export class MerchantsComponent implements OnInit, OnDestroy {
  LIST = 1;
  EDIT = 2;
  ADD = 3;
  activeContainer: number;
  isModalSelectionActive = false;
  //
  merchants: Merchant[] = [];
  myCurrencyCodes: MyCurrency[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  longJob = false;
  iconRemove = faTrash;
  merchantIndexToEdit: number;
  merchantToEdit: Merchant;
  merchantEditFormGroup: FormGroup;
  merchantAddFormGroup: FormGroup;
  //
  private merchantNameValidator = [
    Validators.maxLength(64),
    Validators.minLength(3)
  ];

  constructor(
    private location: PlatformLocation,
    private yubillyService: YubillyService,
    private modalConfig: ModalConfigComponent,
    private modalConfirmationService: ModalConfirmationService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.location.onPopState(() => {
      if (this.isModalSelectionActive) {
        this.modalConfig.closeAll('back')
        this.isModalSelectionActive = false;
      } else if (this.activeContainer == this.EDIT || this.activeContainer == this.ADD) {
        this.activeContainer = this.LIST;
      }
    });
    this.merchantEditFormGroup = this.fb.group({
      merchantName: [''],
      currency: ['']
    })
    this.merchantEditFormGroup.get('merchantName').setValidators(this.merchantNameValidator.concat(Validators.required));
    this.merchantAddFormGroup = this.fb.group({
      merchantName: [''],
      currency: ['']
    })
    this.merchantAddFormGroup.get('merchantName').setValidators(this.merchantNameValidator.concat(Validators.required));
    this.activeContainer = this.LIST;
    //
    this.getMerchantsWithCurrencies();
  }

  getMerchants(): void {
    this.longJob = true;
    this.yubillyService.getMerchants()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.merchants = data;
        this.longJob = false;
      }, error => {
        this.longJob = false;
      }
      );
  }
  getMerchantsWithCurrencies(): void {
    this.longJob = true;
    this.yubillyService.getMerchantsWithCurrencies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.merchants = data[0].merchants;
        this.myCurrencyCodes = data[0].currencies;
        // console.log(this.myCurrencyCodes);
        this.longJob = false;
      }, error => {
        this.longJob = false;
      }
      );
  }
  onMerchantCreate(): void {
    history.pushState(null, null);
    this.activeContainer = this.ADD;
  }

  addMerchant(data): void {
    const merchantToAdd = {
      merchantId: 0, merchantName: data.merchantName,
      invoiceEmail: '', currency: data.currency.currencyCode, inUse: false
    };
    this.longJob = true;
    this.yubillyService.addMerchant(merchantToAdd)
      .pipe(takeUntil(this.destroy$))
      .subscribe((merchant) => {
        this.merchants.push(merchant);
        this.activeContainer = this.LIST;
        this.longJob = false;
        history.back();
      }, error => {
        this.longJob = false;
      }
      );

  }

  cancelCreate(): void {
    this.activeContainer = this.LIST;
    history.back();
  }

  onMerchantEdit(merchant: Merchant): void {
    this.merchantIndexToEdit = this.merchants.indexOf(merchant);
    this.merchantToEdit = Object.assign({}, merchant)
    history.pushState(null, null);
    this.activeContainer = this.EDIT;
  }

  updateMerchant(): void {
    this.longJob = true;
    // console.log(this.merchantToEdit);
    this.yubillyService.updateMerchant(this.merchantToEdit)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.merchants[this.merchantIndexToEdit] = data;
        this.activeContainer = this.LIST;
        this.longJob = false;
        history.back();
      }, error => {
        this.longJob = false;
      }
      );
  }

  cancelEdit(): void {
    this.activeContainer = this.LIST;
    history.back();
  }

  onMerchantRemove(content: string, merchantId: number, merchantIndex: number): void {
    // Are you sure?
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    // this.merchantIndexToEdit = this.merchants.indexOf(merchant);
    this.modalConfig.openV2(content).then((result: any) => {
      this.isModalSelectionActive = false;
      this.yubillyService.removeMerchant(merchantId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.merchants.splice(merchantIndex, 1);
          // this.merchants = data;
        });
    }).catch((reason: any) => {
      if (reason !== 'back') {
        this.isModalSelectionActive = false;
      }
    });
  }

  ngOnDestroy(): any {
    // with takeUntil method, subscriptions are destroyed automatically,
    // however in case of any interruption during subscription
    // we unsubscribe when this component destroyed as well.
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
