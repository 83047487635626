<div class="container">
  <div style="background-color: #fff; margin-top: 80px; text-align:center">
    <div class="row">
      <div *ngIf="isUserSignedIn(); then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <div class="d-none d-sm-block col-sm-12 col-md-12">
          <h2>{{'homeLine1' | translate}}</h2>
          <p class="lead">
            {{'homeLine2' | translate}}
          </p>
          <br />
        </div>
        <br />
        <!--<div class="col-12" *ngIf="isUserMerchantUser()">
          <button (click)="goToClientOffers()" class="btn btn-primary" type="submit">
            <b>{{'manageMerchantOffers'|translate}}</b></button>
        </div>
        <br />-->
      </ng-template>

      <ng-template #elseBlock>
        <div class="col-sm-12 col-md-12">
          <h2>{{'homeLine1' | translate}}</h2>
          <p class="lead">
            {{'homeLine2' | translate}}
          </p>
        </div>

        <br /><br />
        <div class="col-3"></div>
        <a class="col btn btn-primary" href="sign-in" role="button"><b>{{'signIn' | translate}}</b></a>
        <div class="col-3"></div>
      </ng-template>

      <div class="col-12" [hidden]="!isUserSignedIn()">
        <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="qrValue" [scale]="6">
        </ngx-qrcode>
        <h4>{{qrValue}}</h4>
      </div>

    </div>
  </div>
</div>
