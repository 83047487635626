<div class="cmx-auto" style="background-color: #fff; margin-top: 80px; align-content: center;">
  <ng-container *ngIf="activeContainer==LIST">
    <h2 class="page-title">{{'labels' | translate}}</h2>
    <div *ngIf="longJob" class="d-flex spinner-border" role="status"></div>
    <div class="list-group">
      <div class="row">
        <div class="col-sm">
          <div class="table-sm">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th style="text-align:left">{{'labelName'|translate}}</th>
                  <th style="text-align:center">{{'inUse'|translate}}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let label of labels; index as i">
                  <td class="label m-0" [ngStyle]="{'background-color': label.labelColor}" (click)="onLabelEdit(label)">
                    {{label.labelName}}
                  </td>
                  <td class="label m-0" style="text-align:center; font-weight: bold;"
                    [ngStyle]="{'background-color': label.labelColor}" (click)="onLabelEdit(label)">
                    <span *ngIf="label.inUse">&#10003;</span>
                  </td>
                  <td class="m-0" style="text-align:center" [ngStyle]="{'background-color': label.labelColor}">
                    <span *ngIf="!label.inUse">
                      <fa-icon [icon]="iconRemove" style="color:black;" type="button" rounded="true"
                        (click)="onLabelRemove(confirmationTemplate, label.labelId, i)">
                      </fa-icon>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-info btn-sm" (click)="onLabelCreate()">{{'createNewLabel'|translate}}</button>
          </div>
        </div>

      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeContainer==EDIT">
    <h4 class="page-title">{{'labelEdit'|translate}}</h4>

    <form class="yubilly-form" (ngSubmit)="updateLabel()" #labelUpdateForm="ngForm" [formGroup]="labelEditFormGroup">
      <div class="container">

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="labelName">{{'labelName'|translate}}</label>
              <input ngbAutofocus
                [ngClass]="{'input-error':labelEditFormGroup.controls.labelName.invalid && labelEditFormGroup.controls.labelName.touched}"
                class="form-control" formControlName="labelName" type="text" name="labelName"
                [(ngModel)]="labelToEdit.labelName" required id="labelName">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="labelColor">{{'backgroundColor'|translate}}</label>
              <input class="form-control" style="padding: 0.1rem;" formControlName="labelColor" type="color"
                name="labelColor" id="labelColor" [(ngModel)]="labelToEdit.labelColor">
            </div>
          </div>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-block btn-primary btn-sm"
                [disabled]="labelUpdateForm.invalid">{{'save'|translate}}</button>
            </div>
            <div class="col">
              <button type="button" (click)="cancelEdit()" class="btn btn-block btn-secondary btn-sm">
                {{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>

      </div>
    </form>

  </ng-container>

  <ng-container *ngIf="activeContainer==ADD">
    <h4 class="page-title">{{'newLabel'|translate}}</h4>
    <form class="yubilly-form" (ngSubmit)="addLabel(labelAddForm.value)" #labelAddForm="ngForm"
      [formGroup]="labelAddFormGroup">
      <div class="container">

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="labelName">{{'labelName'|translate}}</label>
              <input ngbAutofocus
                [ngClass]="{'input-error':labelAddFormGroup.controls.labelName.invalid && labelAddFormGroup.controls.labelName.touched}"
                class="form-control" formControlName="labelName" id="labelName" ngModel required>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="labelColor">{{'backgroundColor'|translate}}</label>
              <input class="form-control" formControlName="labelColor" style="padding: 0.1rem;" type="color"
                name="labelColor" id="labelColor" ngModel="#FFFFFF">
            </div>
          </div>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-block btn-primary btn-sm"
                [disabled]="labelAddForm.invalid">{{'add'|translate}}</button>
            </div>
            <div class="col">
              <button type="button" (click)="cancelCreate()" class="btn btn-block btn-secondary btn-sm">
                {{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>

      </div>
    </form>
  </ng-container>

  <ng-template #confirmationTemplate let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'pleaseConfirm'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{'areYouSureToRemove'|translate}}
    </div>

    <div class="container-sm">
      <div class="row">
        <div class="col">
          <button type="submit" (click)="c('ok')" class="btn btn-block btn-danger btn-sm">{{'ok'|translate}}</button>
        </div>
        <div class="col">
          <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
            {{'cancel'|translate}}</button>
        </div>
      </div>
    </div>
    <p></p>
  </ng-template>

</div>
