<div class="mx-auto" style="background-color: #fff; max-width: 700px; margin-top: 80px; align-content: center;">
  <ng-container *ngIf="isContainerListActive">
    <h2 id="pageTitle" class="page-title" *ngIf="!longJobRefresh" (click)="refreshReceipts(false)"
      style="cursor: pointer;">
      {{'receipts' | translate}}
    </h2>
    <h2 class="page-title" *ngIf="longJobRefresh">
      <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
    </h2>

    <div class="row" style="margin-bottom: 0%;">
      <div class="col-sm" style="margin-bottom: 0%;">
        <div class="card" style="margin-bottom: 0%; border-radius: 20px; border-width: 6px;">
          <div class="card-header" style="text-align: center; height:2.5rem; line-height: 2.5rem; padding: 0px;">
            <a class="card-link" data-toggle="collapse" (click)="showHideFilters()" style="cursor: pointer;">
              {{'filtersAndTotals'|translate}}</a>
            <span [hidden]="
            selectedMerchant.merchantId === 0
            && selectedLabel.labelId===0
            && selectedPeriod.periodId===0
            && selectedCurrency.currencyCode.startsWith('all')" style="margin-left:0.5rem;">
              <span *ngIf="!longJobFilters">
                <fa-icon [icon]="iconResetFilters" style="color: darkkhaki; font-size:24px;" type="button"
                  (click)="resetFilters()" rounded="true">
                </fa-icon>
              </span>
              <span *ngIf="longJobFilters">
                <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:24px;" rounded="true">
                </fa-icon>
              </span>
            </span>
          </div>
          <div id="filters" class="collapse {{filtersShow}}" style="margin-bottom: 0%;">
            <div class="card-body" style="margin-bottom: 0%;">
              <div class="table-sm">
                <table class="table table-sm" style="width:100%; margin: 0 auto;">
                  <tr>
                    <td style="text-align: center;">
                      <select class="filter" [(ngModel)]="selectedMerchant" (change)="refreshReceipts(false)">
                        <option *ngFor="let merchant of merchantsForFilter" [ngValue]="merchant">
                          <span
                            *ngIf="(merchant.merchantId == null || merchant.merchantId === 0)"><b>{{merchant.merchantName|translate}}</b>
                          </span>
                          <span
                            *ngIf="(merchant.merchantId !== null && merchant.merchantId !== 0)">{{merchant.merchantName}}
                          </span>
                        </option>
                      </select>
                      <select class="filter" [(ngModel)]="selectedLabel" (change)="refreshReceipts(false)">
                        <option *ngFor="let label of labels" [ngValue]="label">
                          <span *ngIf="label.labelId==0"><b>{{label.labelName|translate}}</b></span>
                          <span *ngIf="label.labelId!=0">{{label.labelName}}</span>
                        </option>
                      </select>
                      <br />
                      <select class="filter" [(ngModel)]="selectedPeriod" (change)="refreshReceipts(false)">
                        <option *ngFor="let period of periods" [ngValue]="period">{{period.period|translate}}</option>
                      </select>
                      <select class="filter-currency" [(ngModel)]="selectedCurrency" (change)="refreshReceipts(false)">
                        <option *ngFor="let currency of myCurrencyCodes" [ngValue]="currency">
                          <span
                            *ngIf="currency.currencyCode=='allCurrencies'"><b>{{currency.currencyCode|translate}}</b></span>
                          <span *ngIf="currency.currencyCode!='allCurrencies'">{{currency.currencyCode}}</span>
                        </option>
                      </select>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="row" style="margin-bottom: 0%;">
                <div class="col" style="margin-bottom: 0%;">
                  <div class="table-sm" style="margin-bottom: 0%;">
                    <table class="table table-sm" style="margin-bottom: 0%;">
                      <tr>
                        <td style="text-align: center;" style="margin-bottom: 0%;">
                          <table class="table table-sm" style="margin-bottom: 0%;">
                            <tr *ngFor="let total of totals">
                              <td class="m-0" style="width: 20%;"></td>
                              <td class="m-0"
                                style="text-align:right; padding-top: 0%; padding-bottom: 0%; white-space: nowrap;">
                                <b>{{total.amount}}</b>
                              </td>
                              <td class="m-0"
                                style="text-align:left; padding-top: 0%; padding-bottom: 0%; white-space: nowrap;">
                                {{total.currency==''? ('N/A'|translate) : total.currency}}
                                (<b>{{total.qty}}</b> {{'pieces'|translate}}) </td>
                              <td class="m-0" style="width: 20%;"></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <button type="button" (click)="exportData(confirmationTemplate)"
                      class="btn btn-block btn-secondary btn-sm">
                      {{'exportData'|translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="list-group" style="margin-top: 0.0rem; ">
      <div class="row" style="margin-top: 0%;">
        <div class="col-sm" style="margin-top: 0%;">
          <div class="table-sm" style="margin-top: 0%;">
            <table class="table table-striped table-sm" style="margin-top: 0%;">
              <thead>
                <tr>
                  <th></th>
                  <th style="text-align:center; font-size: smaller; ">
                    <span style="margin-bottom:0pt;">{{'date'|translate}}</span>
                    <!--<p style="font-size: smaller; font-weight: lighter; margin:0pt;white-space: nowrap;">
                      ({{'displayDateFormat'|translate}})</p>-->
                  </th>
                  <th style="vertical-align: top; font-size: smaller; ">{{'merchant'|translate}}
                  </th>
                  <th style="text-align:right; vertical-align: top; font-size: smaller;">{{'total'|translate}}
                  </th>
                  <th style="vertical-align: top; font-size: smaller;">{{'currencyShort'|translate}}
                  </th>
                  <!--<th></th>-->
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let receipt of receipts" >
                <tr style="cursor: pointer;">
                  <td class="align-middle m-0" style="text-align:center;">
                    <span *ngIf="receipt.hasItems">
                      <fa-icon [icon]="iconReceipt" style="color:black;" type="button" [disabled]="downloadingItems"
                        (click)="getReceiptItems(receipt)" id="image" rounded="true">
                      </fa-icon>
                    </span>
                  </td>
                  <td class="date align-middle m-0" style="text-align:center" (click)="onReceiptUpdate(receipt)">
                    {{receipt.invoiceDate | date:'yyyy-MM-dd'}}</td>
                  <td class="merchant align-middle m-0" (click)="onReceiptUpdate(receipt)">
                    {{receipt.merchantName}}
                    <br />
                    <span class="label"
                      [ngStyle]="{'background-color': receipt.labelColor}">{{receipt.labelName}}</span>
                  </td>
                  <td class="total align-middle m-0" style="text-align:right" (click)="onReceiptUpdate(receipt)">
                    {{ receipt.total===''? '' : (receipt.total | number:'.2-2') }}
                  </td>
                  <td class="currency align-middle m-0" style="text-align:left" (click)="onReceiptUpdate(receipt)">
                    {{receipt.currency}}
                  </td>
                </tr>
                <tr *ngIf="receipt.showItems"></tr>
                <tr *ngIf="receipt.showItems">
                  <td colspan="5" style="border-bottom: 2px solid coral;">
                    <table style="font-size: smaller;margin-left:auto;margin-right:auto;">
                      <tr *ngFor="let i of receipt.items">
                        <td colspan="2">{{i.item}}</td>
                        <td>{{i.quantity}}</td>
                        <td>{{i.unitPrice}}</td>
                        <td style="text-align: right;">{{i.price}}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <!--<hr *ngIf="receipt.showItems" style="border: 1px solid red;">-->
                <!--<tr><td span="5">Receipt Items</td></tr>-->
                  <!--<td class="receipt-buttons align-middle m-0">
                    <div class="btn-group">
                      <button style="padding: 0px; font-size:16px; border: 0px;">
                        <span *ngIf="!receipt.downloadingEmail">
                          <fa-icon [icon]="iconEmail" style="color:black;" type="button"
                            (click)="getReceiptEmail(receipt)" id="email" rounded="true">
                          </fa-icon>
                        </span>
                        <span *ngIf="receipt.downloadingEmail">
                          <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black;" rounded="true"></fa-icon>
                        </span>
                      </button>

                      <button style="margin-left:4px; padding: 0px; font-size:16px; border: 0px;">
                        <span *ngIf="receipt.hasPdf">
                          <span *ngIf="!receipt.downloadingReceipt">
                            <fa-icon [icon]="iconReceipt" style="color:black;" type="button"
                              (click)="getReceiptFile(receipt)" id="image" rounded="true">
                            </fa-icon>
                          </span>
                          <span *ngIf="receipt.downloadingReceipt">
                            <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black;" rounded="true">
                            </fa-icon>
                          </span>
                        </span>
                      </button>
                    </div>
                  </td>-->
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--Pagination not implemented on client side yet.
        It should be added in final apps.
      <div class="container-sm">
        <div class="row">
          <div class="col pagination">
            <a (click)="onPreviousPage()">❮</a>
            <label style="padding: 6px 6px">{{pageNumber}}</label>
            <a (click)="onNextPage()">❯</a>
          </div>
          <div class="col" style="text-align: right;">
            <label style="padding-top: 6px; padding-bottom: 6px; text-align: right;">
              {{'pageSize' | translate}}: {{pageSize}}</label>
          </div>
        </div>
      </div>
    -->
    </div>

  </ng-container>

  <ng-container *ngIf="!isContainerListActive">

    <!-- <div class="modal-header"> -->
    <h4 class="page-title">{{'receiptEdit'|translate}}</h4>
    <!--</div>-->

    <div class="card" style="margin-bottom: 10px; border-radius: 20px; border-width: 6px;">
      <div class="card-header"
        style="text-align: center; vertical-align: middle; height:2.6rem; line-height: 2.5rem; padding: 0px;">
        {{'attachments'|translate}} :

        <div class="btn-group">
          <button style="margin-left:8px; padding: 0px; font-size:20px; border: 0px;">
            <span *ngIf="!receiptToEdit.downloadingEmail">
              <fa-icon [icon]="iconEmail" style="color:rgb(49, 48, 30);" type="button"
                (click)="getReceiptEmail2(receiptToEdit)" id="email" rounded="true">
              </fa-icon>
            </span>
            <span *ngIf="receiptToEdit.downloadingEmail">
              <fa-icon [icon]="iconSpinner" [spin]="true" style="color:rgb(49, 48, 30);" type="button"
                (click)="getReceiptEmail2(receiptToEdit)" id="email" rounded="true">;" rounded="true"></fa-icon>
            </span>
          </button>

          <button style="margin-left:8px; padding: 0px; font-size:20px; border: 0px;">
            <span *ngIf="receiptToEdit.hasAttachment">
              <span *ngIf="!receiptToEdit.downloadingReceipt">
                <fa-icon [icon]="iconReceipt" style="color:black;" type="button"
                  (click)="getReceiptAttachment(receiptToEdit)" id="image" rounded="true">
                </fa-icon>
              </span>
              <span *ngIf="receiptToEdit.downloadingReceipt">
                <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black;" rounded="true">
                </fa-icon>
              </span>
            </span>
          </button>
        </div>

      </div>
    </div>

    <form class="yubilly-form" (ngSubmit)="submitReceipt()" [formGroup]="receiptEdit" #receiptUpdateForm="ngForm">
      <div class="container">

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="receiptDate">{{'date'|translate}}</label>
              <input ngbAutofocus class="form-control" type="date" name="receiptDate" formControlName="receiptDate"
                [(ngModel)]="receiptToEdit.invoiceDate" required id="receiptDate">
            </div>
          </div>
        </div>

        <div class="border border-primary rounded" style="padding-left: 0.5rem; padding-right: 0.5rem; padding-top: 0.5rem; padding-bottom: 0.7rem;
          margin-left: -0.5rem; margin-right:-0.5rem; margin-bottom: 0.5rem;">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="merchantName">{{'merchantName'|translate}}</label>
                <input class="form-control" type="text" name="merchantName" id="merchantName"
                  formControlName="merchantName" [(ngModel)]="receiptToEdit.merchantName" style="font-weight: bold;"
                  disabled>
                <span style="font-size: smaller;">[{{'from'|translate}}: {{receiptToEdit.invoiceEmail}}]</span>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col">
                <button type="button" (click)="onEditMerchantName(contentMerchantNameEditTemplate)"
                  class="btn btn-outline-primary btn-sm">
                  {{'editName'|translate}}</button>
              </div>
              <!--<div class="col">
                <button type="button" (click)="onMerchantSelection(contentMerchantSelectionTemplate)"
                  class="btn btn-block btn-outline-primary btn-sm">
                  {{'changeMerchant'|translate}}</button>
              </div>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-7">
            <div class="form-group">
              <label for="receiptTotal">{{'total'|translate}}</label>
              <input class="form-control" type="text" required name="receiptTotal" id="receiptTotal"
                formControlName="receiptTotal" [(ngModel)]="receiptToEdit.total" appAmount>
            </div>
          </div>
          <div class="col-5">
            <div class="form-group" (click)="onCurrencySelection(contentCurrencySelectionTemplate)">
              <label for="currency">{{'currency'|translate}}</label>
              <input class="form-control" type="text" required name="currency" id="currency" formControlName="currency"
                [(ngModel)]="receiptToEdit.currency" disabled>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group" (click)="onLabelSelection(contentLabelSelectionTemplate)">
              <label for="labelName">{{'label'|translate}}</label>
              <input ngbAutofocus class="form-control" type="text" name="labelName"
                [ngStyle]="{'background-color': receiptToEdit.labelColor}" formControlName="labelName"
                [(ngModel)]="receiptToEdit.labelName" required id="labelName" disabled>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col">
            <button type="submit" class="btn btn-block btn-primary btn-sm">{{'ok'|translate}}</button>
          </div>
          <div class="col">
            <button type="button" (click)="cancelEdit()" class="btn btn-block btn-secondary btn-sm">
              {{'cancel'|translate}}</button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <button type="button" (click)="moveToRecycleBin(receiptToEdit.receiptId)"
              class="btn btn-block btn-danger btn-sm">{{'moveToRecycle'|translate}}</button>
          </div>
        </div>

        <p></p>
        <!--{{receiptUpdateForm.invalid}}-->
      </div>
    </form>

    <ng-template #contentMerchantNameEditTemplate let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'editMerchantName'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label for="merchantNameEdit">{{'merchantName'|translate}}</label>
        <br />
        <input class="form-control" type="text" name="merchantNameEdit" id="merchantNameEdit"
          style="width:100%; font-weight: bold;" [(ngModel)]="merchantNameEdit">
        <span style="font-size: smaller;">[{{'from'|translate}}: {{receiptToEdit.invoiceEmail}}]</span>
      </div>

      <div class="container-sm">
        <div class="row">
          <div class="col">
            <button type="submit" (click)="c('ok')" class="btn btn-block btn-primary btn-sm">{{'ok'|translate}}</button>
          </div>
          <div class="col">
            <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
              {{'cancel'|translate}}</button>
          </div>
        </div>
      </div>
      <p></p>
    </ng-template>

    <ng-template #contentMerchantSelectionTemplate let-c="close" let-d="dismiss" style="max-height: 75%;">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'merchants'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <li *ngFor="let merchant of merchants" (click)="selectMerchant(merchant); c('ok')">
            {{merchant.merchantName}} [<small>{{merchant.invoiceEmail}}</small>]
            <hr>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <div class="form-group">
          <button type="button" (click)="d('cancel')" class="btn btn-secondary btn-sm"
            style="margin-left: 10px;">{{'cancel'|translate}}
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #contentLabelSelectionTemplate let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'labels'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <li *ngFor="let label of labels" [ngStyle]="{'background-color': label.labelColor}" class="align-middle"
            (click)="selectLabel(label); c('ok')">
            <span *ngIf="label.labelId==0">{{'noLabel'|translate}}</span>
            <span *ngIf="label.labelId!=0">{{label.labelName}}</span>
            <hr>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <div class="form-group">
          <button type="button" (click)="d('cancel')" class="btn btn-secondary btn-sm"
            style="margin-left: 10px;">{{'cancel'|translate}}
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #contentCurrencySelectionTemplate let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'currencies'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <li *ngFor="let myCurrency of myCurrencyCodes" class="align-middle"
            (click)="selectCurrency(myCurrency); c('ok')">
            <span *ngIf="myCurrency.currencyCode=='allCurrencies'">{{'noCurrency'|translate}}</span>
            <span *ngIf="myCurrency.currencyCode!='allCurrencies'">{{myCurrency.currencyCode}}</span>
            <hr>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <div class="form-group">
          <button type="button" (click)="d('cancel')" class="btn btn-secondary btn-sm"
            style="margin-left: 10px;">{{'cancel'|translate}}
          </button>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #confirmationTemplate let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'pleaseConfirm'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label for="fileName">{{'fileName' | translate}}</label>
      <br />
      <input style="width:100%" [ngClass]="" type="text" name="fileName" [(ngModel)]="fileName">
    </div>

    <div class="container-sm">
      <div class="row">
        <div class="col">
          <button type="submit" (click)="c('ok')" class="btn btn-block btn-primary btn-sm">{{'ok'|translate}}</button>
        </div>
        <div class="col">
          <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
            {{'cancel'|translate}}</button>
        </div>
      </div>
    </div>
    <p></p>
  </ng-template>

</div>
