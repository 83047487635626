<div class="mx-auto" style="background-color: #fff; max-width: 700px; margin-top: 80px; align-content: center;">

  <h2 class="page-title" *ngIf="!longJob" (click)="getOthers()" style="cursor: pointer;">
    {{'junk' | translate}}
  </h2>
  <h2 class="page-title" *ngIf="longJob">
    <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
  </h2>
  <div class="list-group" style="margin-top: 0.0rem;">
    <div class="row" style="margin-top: 0%;">
      <div class="col-sm" style="margin-top: 0%;">
        <div class="table-sm" style="margin-top: 0%;">
          <table class="table table-striped table-sm" style="margin-top: 0%;">
            <thead>
              <tr>
                <th style="vertical-align: top; text-align:left;">
                  {{'receivedTime' | translate}}</th>
                <th style="vertical-align: top; text-align:left;">
                  {{'fromSubject' | translate}}</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let other of others; index as i" style="cursor: pointer;">
                <td class="m-0 align-middle" style="white-space: nowrap; width:30%; text-align: center;">
                  {{other.receivedTime | date:'yyyy-MM-dd HH:mm'}}
                  <br />
                  <span *ngIf="!other.downloadingOther">
                    <fa-icon [icon]="iconEmail" style="color:black;" type="button" rounded="true"
                      (click)="onJunkEmail(other)">
                    </fa-icon>
                  </span>
                  <span *ngIf="other.downloadingOther">
                    <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black;" rounded="true">
                    </fa-icon>
                  </span>
                  <fa-icon [icon]="iconRemove" style="color:black; margin-left: 1rem;" type="button" rounded="true"
                    (click)="onJunkRemove(confirmationTemplate, other.otherId, i)">
                  </fa-icon>
                </td>
                <td class="emailFrom m-0 align-middle" style="width:70%">
                  {{other.receivedFrom}}<br />{{other.subject}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <ng-template #confirmationTemplate let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'pleaseConfirm'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{'areYouSureToRemove'|translate}}
    </div>

    <div class="container-sm">
      <div class="row">
        <div class="col">
          <button type="submit" (click)="c('ok')" class="btn btn-block btn-danger btn-sm">{{'ok'|translate}}</button>
        </div>
        <div class="col">
          <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
            {{'cancel'|translate}}</button>
        </div>
      </div>
    </div>
    <p></p>
  </ng-template>

  <!--Pagination not implemented on client side yet.
      It should be added in final apps.
    <div class="container-sm">
      <div class="row">
        <div class="col pagination">
          <a (click)="onPreviousPage()">❮</a>
          <label style="padding: 6px 6px">{{pageNumber}}</label>
          <a (click)="onNextPage()">❯</a>
        </div>
        <div class="col" style="text-align: right;">
          <label style="padding-top: 6px; padding-bottom: 6px; text-align: right;">
            {{'pageSize' | translate}}: {{pageSize}}</label>
        </div>
      </div>
    </div>
  -->
  <!--</div>-->
</div>
