import { environment } from './../../../environments/environment';
// import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ClientService } from './../../shared/client.service';
import { Membership } from './../../shared/client-data';
import { Component, OnInit } from '@angular/core';
import { NgModel, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})

export class MembershipComponent implements OnInit {
  aFormGroup: FormGroup;
  currentLanguage = 'en';
  siteKey = environment.recaptchaNotRobot.siteKeyNotRobot;
  captchaResolved = false;
  protected captchaResponse = '';
  agreed = false;
  //
  destroy$: Subject<boolean> = new Subject<boolean>();
  membership: Membership = {
    clientName: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    contactName: '',
    tel: '',
    email: '',
    website: '',
    recaptcha: ''
  };
  membershipFormGroup: FormGroup;
  submitting = false;
  letters = '[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœĞÜŞİÖÇğüöşı]';
  numbers = '0123456789';

  private nameValidators = [
    Validators.maxLength(50),
    Validators.minLength(3),
    Validators.pattern('^(' + this.letters + '+[ \.])+' + this.letters + '+[\.]?' + '$|^' + this.letters + '+$')
  ];

  private telValidators = [
    Validators.maxLength(20),
    Validators.minLength(10),
    Validators.pattern('[0-9[ .-]{10,20}$')
  ];

  private verificationCodeValidators = [
    Validators.pattern('[0-9]{6,6}$')
  ];
  private emailValidators = [
    Validators.maxLength(100),
    Validators.minLength(5),
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
  ];

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.captchaResolved = false;

    this.membershipFormGroup = this.formBuilder.group({
      clientName: [''],
      address: [''],
      city: [''],
      province: [''],
      postalCode: [''],
      country: [''],
      contactName: [''],
      tel: [''],
      email: [''],
      website: ['']
    })
    this.membershipFormGroup.get('clientName').setValidators(this.nameValidators.concat(Validators.required));
    this.membershipFormGroup.get('address').setValidators(Validators.required);
    this.membershipFormGroup.get('city').setValidators(Validators.required);
    this.membershipFormGroup.get('province').setValidators(Validators.required);
    this.membershipFormGroup.get('postalCode').setValidators(Validators.required);
    this.membershipFormGroup.get('country').setValidators(Validators.required);
    this.membershipFormGroup.get('contactName').setValidators(this.nameValidators.concat(Validators.required));
    this.membershipFormGroup.get('tel').setValidators(this.telValidators.concat(Validators.required));
    this.membershipFormGroup.get('email').setValidators(this.emailValidators.concat(Validators.required));
    //
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
  }

  submitApplication(form: NgForm): void {
    this.submitting = true;
    const x: string = form.value.contactName;
    // console.log(x);
    this.membership.clientName = form.value.clientName;
    this.membership.address = form.value.address;
    this.membership.city = form.value.city;
    this.membership.province = form.value.province;
    this.membership.postalCode = form.value.postalCode;
    this.membership.country = form.value.country;
    this.membership.contactName = form.value.contactName;
    this.membership.tel = form.value.tel;
    this.membership.email = form.value.email;
    this.membership.website = form.value.website;
    this.membership.recaptcha = this.captchaResponse;

    this.clientService.submitMembershipApplication(this.membership)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.submitting = false;
        this.router.navigateByUrl('/sign-in');
      }, error => {
        // this.error = error;
        this.submitting = false;
      }
      );
  }
  changeRecaptchaLanguage(): void {
    this.currentLanguage = this.translate.currentLang;
  }
  handleReset(): void {
    this.captchaResolved = false;
    this.captchaResponse = '';
  }
  handleExpire(): void {
    this.captchaResolved = false;
    this.captchaResponse = '';
  }
  handleLoad(): void {
    this.captchaResolved = false;
    this.captchaResponse = '';
  }
  handleSuccess(captchaResponse: string): void {
    this.captchaResolved = true;
    this.captchaResponse = captchaResponse;
    // console.log(captchaResponse);
  }

  agreeChecked(): void {
    this.agreed = (document.getElementById('agree') as HTMLInputElement).checked;
  }
}
