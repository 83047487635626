import { ModalConfigComponent } from './../modal-config/modal-config.component';
import { YubillyService } from './../../shared/yubilly.service';
import { Other } from './../../shared/yubilly-data';
import { Component, OnInit } from '@angular/core';
import { faEnvelope, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.css']
})
export class OthersComponent implements OnInit {
  longJob = false;
  isModalSelectionActive = false;
  downloadingEmail = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  iconEmail = faEnvelope;
  iconRemove = faTrash;
  currentLanguage = 'en';
  iconSpinner = faSpinner;
  others: Other[] = [];
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(
    private yubillyService: YubillyService,
    private modalConfig: ModalConfigComponent,) {
  }

  ngOnInit(): void {
    this.getOthers();
  }

  getOthers() {
    this.longJob = true;
    this.yubillyService
      .getOthers()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          console.log(data[0]);
          this.longJob = false;
          this.others = data;
        },
        (error) => {
          this.longJob = false;
        }
      );
  }

  onJunkRemove(content: string, otherId: number, i: number): void {
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig.openV2(content).then((result: any) => {
      this.isModalSelectionActive = false;
      this.yubillyService.removeOther(otherId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.others.splice(i, 1);
        });
    }).catch((reason: any) => {
      if (reason !== 'back') {
        this.isModalSelectionActive = false;
      }
    });

  }
  onJunkEmail(other: Other): void {
    other.downloadingOther = true;
    this.downloadingEmail = true;
    this.yubillyService
      .getOtherEmail(other.otherId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/eml' });
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download =
            other.receivedFrom +
            this.datePipe.transform(other.receivedTime, '_yyyyMMdd_HHmmss') +
            '.eml';
          link.click();
          other.downloadingOther = false;
          this.downloadingEmail = false;
        },
        (error) => {
          other.downloadingOther = false;
          this.downloadingEmail = false;

        }
      );  }
}
