<div class="container">
  <div style="background-color: #fff; margin-right: 25px; margin-top: 80px;">
    <h3 class="page-title">{{'yubillyAccount' | translate}}</h3>
    <h3 class="page-title">{{'signIn' | translate}}</h3>
    <form [formGroup]="sigInFormGroup" (ngSubmit)="loginWithCognito(newPasswordRequiredForm)">
      <div class="form-group" style="max-width: 350px;">
        <label for="userName">{{'userName'|translate}}</label>
        <input ngbAutofocus
          [ngClass]="{'input-error':sigInFormGroup.controls.userName.invalid && sigInFormGroup.controls.userName.touched}"
          class="form-control" formControlName="userName" type="email" name="userName"
          placeholder="{{'enterYourUserName'|translate}}">
      </div>

      <div class="form-group" style="max-width: 350px;">
        <label for="password">{{'password'|translate}}</label>
        <input
          [ngClass]="{'input-error':sigInFormGroup.controls.password.invalid && sigInFormGroup.controls.password.touched}"
          class="form-control" formControlName="password" type="password"
          placeholder="{{'enterYourPassword'|translate}}">
      </div>
      <div>
        <br />
        <button class="btn btn-primary" type="submit" [disabled]="sigInFormGroup.invalid  || loading">{{'signIn'|translate}}
        </button>
      </div>
      <br />
      <p>
        <!--enter the verified email address, [SUBMIT] 
        userName will be sent there, check your email  -->
        <a class="text-primary ml-1" (click)="openModalForm(contentForgotUserName)">{{'forgotUserName'|translate}} </a>
        <br />
        <!--these processes will be done at the same period sequintally
          Enter your userName, [Request Password Reset], verification code sent to email 
          Enter verification code, Enter new password, Retypr new Password
          [SUBMIT]   -->
        <a class="text-primary ml-1" (click)="openModalForm(contentForgotPassword)">{{'forgotPassword'|translate}}
        </a>

      </p>
      <p class="text-dark">
        {{'notAMember'|translate}}
        <a class="text-primary ml-1" href="/sign-up">{{'signUp'|translate}} </a>
      </p>
    </form>
    <!--<ul>
    <li>Form valid? {{sigInFormGroup.valid}}</li>
    <li>
      <pre>{{sigInFormGroup.controls.userName.errors| json}}</pre>
    </li>
  </ul>-->

    <ng-template #contentForgotUserName let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'forgotUserName'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form (ngSubmit)="submitEmailForUsernameRecovery()" [formGroup]="emailFormGroup" #forgotUserNameForm="ngForm">
        <div class="modal-body">
          <div class="form-group" style="max-width: 400px;">
            <label for="emailInput">{{'enterYourEmail' | translate}}</label>
            <input ngbAutofocus
              [ngClass]="{'input-error':emailFormGroup.controls.email.invalid && emailFormGroup.controls.email.touched}"
              class="form-control" formControlName="email" type="email">
            <span>{{'weWillSendUserName' | translate}}</span>
          </div>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-block btn-primary btn-sm"
                [disabled]="forgotUserNameForm.invalid || loading">{{'ok'|translate}}</button>
            </div>
            <div class="col">
              <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
                {{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>
        <p></p>

      </form>
    </ng-template>

    <ng-template #contentForgotPassword let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'forgotPassword'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form (ngSubmit)="submitUsernameForPasswordRecovery(contentNewPasswordSubmitForm)" [formGroup]="ymailFormGroup"
        #forgotPasswordForm="ngForm">
        <div class="modal-body">
          <div class="form-group" style="max-width: 400px;">
            <label for="ymailInput">{{'enterYourUserName' | translate}}</label>
            <input ngbAutofocus
              [ngClass]="{'input-error':ymailFormGroup.controls.ymail.invalid && ymailFormGroup.controls.ymail.touched}"
              class="form-control" formControlName="ymail" type="email">
          </div>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-block btn-primary btn-sm"
                [disabled]="forgotPasswordForm.invalid  || loading">{{'ok'|translate}}</button>
            </div>
            <div class="col">
              <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
                {{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>
        <p></p>
      </form>
    </ng-template>

    <ng-template #contentNewPasswordSubmitForm let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'passwordRecovery'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form (ngSubmit)="submitNewPasswordWithVerificationCode()" [formGroup]="passwordRecoveryFormGroup"
        #forgotPasswordSubmitForm="ngForm">
        <div class="modal-body">
          <div>{{'verificationCodeSentToEmail' | translate}} <br /> {{'checkYourEmail' | translate}}</div>
          <br />
          <div class="form-group" style="max-width: 400px;">
            <label for="verificationCode">{{'enterVerificationCode' | translate}}</label>
            <input ngbAutofocus
              [ngClass]="{'input-error':passwordRecoveryFormGroup.controls.verificationCode.invalid && passwordRecoveryFormGroup.controls.verificationCode.touched}"
              class="form-control" formControlName="verificationCode" type="text">
          </div>
          <div class="form-group" style="max-width: 400px;">
            <label for="newPassword">{{'enterYourNewPassword'|translate}}</label>
            <input
              [ngClass]="{'input-error': passwordRecoveryFormGroup.controls.newPassword.invalid && passwordRecoveryFormGroup.controls.newPassword.touched}"
              class="form-control" formControlName="newPassword" type="password" [(ngModel)]="newPassword">
          </div>
          <div class="form-group" style="max-width: 400px;">
            <label for="password">{{'reEnterYourNewPassword'|translate}}</label>
            <input [ngClass]="{'input-error':
            (passwordRecoveryFormGroup.controls.reEnteredPassword.invalid || (newPassword!==reEnteredPassword)) 
            && passwordRecoveryFormGroup.controls.reEnteredPassword.touched}" class="form-control"
              formControlName="reEnteredPassword" type="password" [(ngModel)]="reEnteredPassword">
            <small><span class="text-danger"
                *ngIf="reEnteredPasswordError">{{'reEnteredPasswordNotSame'|translate}}</span></small>
          </div>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-block btn-primary btn-sm"
                [disabled]="passwordRecoveryFormGroup.invalid  || loading">{{'ok'|translate}}</button>
            </div>
            <div class="col">
              <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
                {{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>
        <p></p>

      </form>
    </ng-template>

    <ng-template #newPasswordRequiredForm let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'passwordRecovery'|translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form (ngSubmit)="completeNewPassword()" [formGroup]="newPasswordFormGroup" #newPasswordSubmitForm="ngForm">
        <div class="modal-body">
          <div class="form-group" style="max-width: 400px;">
            <label for="newPassword">{{'enterYourNewPassword'|translate}}</label>
            <input ngbAutofocus
              [ngClass]="{'input-error': newPasswordFormGroup.controls.newPassword.invalid && newPasswordFormGroup.controls.newPassword.touched}"
              class="form-control" formControlName="newPassword" type="password" [(ngModel)]="newPassword">
          </div>
          <div class="form-group" style="max-width: 400px;">
            <label for="password">{{'reEnterYourNewPassword'|translate}}</label>
            <input [ngClass]="{'input-error':
            (newPasswordFormGroup.controls.reEnteredPassword.invalid || (newPassword!==reEnteredPassword)) 
            && newPasswordFormGroup.controls.reEnteredPassword.touched}" class="form-control"
              formControlName="reEnteredPassword" type="password" [(ngModel)]="reEnteredPassword">
            <small><span class="text-danger"
                *ngIf="reEnteredPasswordError">{{'reEnteredPasswordNotSame'|translate}}</span></small>
          </div>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-block btn-primary btn-sm"
                [disabled]="newPasswordFormGroup.invalid || loading">{{'submit'|translate}}</button>
            </div>
            <div class="col">
              <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
                {{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>
        <p></p>

      </form>
    </ng-template>
  </div>
</div>