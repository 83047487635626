import { ClientOffersComponent } from './../client-offers/client-offers.component';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/components/auth/auth.service';
import { Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from 'ngx-qrcode2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrValue: string;

  constructor(private appRouter: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    // this.authService.verifyUserStatus();
    this.qrValue = this.authService.getUserName();
    console.log('isUserMerchantUser:'+this.authService.isUserMerchantUser());
  }

  isUserSignedIn(): boolean {
    this.qrValue = this.authService.getUserName();
    return this.authService.isUserSignedIn();
  }

  isUserMerchantUser(): boolean {
    return this.authService.isUserMerchantUser();
  }
  switchToOfferAdmin(): void {
    // this.authService.verifyUserStatus();
    // get client name, client status, user role and current offer list
    console.log('Switching to Offer Admin');
  }

  signOut(): void {
    this.qrValue = '';
    this.authService.signOut();
  }

  goToMerchants(): void {
    this.appRouter.navigate(['/merchants']);
  }
  goToLabels(): void {
    this.appRouter.navigate(['/labels']);
  }
  goToCurrencies(): void {
    this.appRouter.navigate(['/currencies']);
  }
  goToProfile(): void {
    this.appRouter.navigate(['/profile']);
  }
  goToClientOffers(): void {
    this.appRouter.navigate(['/manage-offers']);
  }

}
