<div class="mx-auto" style="background-color: #fff; max-width: 700px; margin-top: 80px; align-content: center;">
  <h2 class="page-title" *ngIf="!longJob" (click)="getOffersWithImageLinks(1,10)" style="cursor: pointer;">
    {{'offers' | translate}}
  </h2>
  <h2 class="page-title" *ngIf="longJob">
    <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
  </h2>

  <div *ngIf="currentOriginIsTest; then content else temporaryContent"></div>
  <ng-template #content>
    <div class="card offer" *ngFor="let offer of offers">
      <div class="card-header offer-header">{{offer.header}}</div>
      <div class="card-title">{{offer.title}}</div>
      <span *ngIf="offer.offerLink===''">
        <img class="card-img-bottom images" alt="{{offer.header}}" src="{{offer.signedImageUrl}}">
      </span>
      <span *ngIf="offer.offerLink!==''">
        <!--<a [(href)]="offer.offerLink" target="_blank">-->
        <a [(href)]="offer.offerLink" target="_blank" style="cursor:pointer"
          (click)="gotoOfferCount(offer.offerId);">
          <img class="card-img-bottom images" alt="{{offer.header}}" src="{{offer.signedImageUrl}}">
        </a>
        <!--<a class ='btn' style="cursor:pointer" (click)="gotoOfferLink(offer.offerId, offer.offerLink);">
          <img class="card-img-bottom images" alt="{{offer.header}}" src="{{offer.signedImageUrl}}">
        </a>-->
      </span>
      <div class="card-body">{{offer.message}}</div>
    </div>
  </ng-template>

  <ng-template #temporaryContent>
    <div class="card-group">
      <div class="row d-flex justify-content-center">
        <div class="col">
          <div class="card offer">
            <img class="card-img-top" [(src)]="yourOfferHere" alt="your-offer-here" id="yourOfferHereId">
            <div class="card-img-body">
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

</div>
