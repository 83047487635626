import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Label } from '@app/shared/yubilly-data';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { YubillyService } from '@app/shared/yubilly.service';
import { PlatformLocation } from '@angular/common';
import { ModalConfigComponent } from '../modal-config/modal-config.component';
import { Form, NgModel, FormGroup, Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ReCaptchaV3Service } from 'ngx-captcha';
// import { ModalConfirmationService } from '../modal-confirmation/modal-confirmation.service';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.css']
})

export class LabelsComponent implements OnInit, OnDestroy {
  LIST = 1;
  EDIT = 2;
  ADD = 3;
  activeContainer: number;
  isModalSelectionActive = false;
  //
  labels: Label[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  longJob = false;
  iconRemove = faTrash;
  labelIndexToEdit: number;
  labelToEdit: Label;
  labelEditFormGroup: FormGroup;
  labelAddFormGroup: FormGroup;
  private labelNameValidator = [
    Validators.maxLength(64),
    Validators.minLength(3)
  ];
  constructor(
    private location: PlatformLocation,
    private yubillyService: YubillyService,
    private modalConfig: ModalConfigComponent,
    // private modalConfirmationService: ModalConfirmationService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.location.onPopState(() => {
      if (this.isModalSelectionActive) {
        this.modalConfig.closeAll('back')
        this.isModalSelectionActive = false;
      } else if (this.activeContainer == this.EDIT || this.activeContainer == this.ADD) {
        this.activeContainer = this.LIST;
      }
    });
    this.labelEditFormGroup = this.fb.group({
      labelName: [''],
      labelColor: ['']
    })
    this.labelEditFormGroup.get('labelName').setValidators(this.labelNameValidator.concat(Validators.required));
    this.labelAddFormGroup = this.fb.group({
      labelName: [''],
      labelColor: ['']
    })
    this.labelAddFormGroup.get('labelName').setValidators(this.labelNameValidator.concat(Validators.required));

    this.activeContainer = this.LIST;
    this.getLabels();
  }
  getLabels(): void {
    this.longJob = true;
    this.yubillyService.getLabels()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.labels = data;
        this.longJob = false;
      }, error => {
        this.longJob = false;
      }
      );
  }

  onLabelCreate(): void {
    history.pushState(null, null);
    this.activeContainer = this.ADD;
  }

  addLabel(data): void {
    const labelToAdd = {
      labelId: 0, labelName: data.labelName, labelColor: data.labelColor, inUse: false };
    this.longJob = true;
    this.yubillyService.addLabel(labelToAdd)
      .pipe(takeUntil(this.destroy$))
      .subscribe((label) => {
        this.labels.push(label);
        this.activeContainer = this.LIST;
        this.longJob = false;
        history.back();
      }, error => {
        this.longJob = false;
      }
      );

  }

  cancelCreate(): void {
    this.activeContainer = this.LIST;
    history.back();
  }

  onLabelEdit(label: Label): void {
    this.labelIndexToEdit = this.labels.indexOf(label);
    this.labelToEdit = Object.assign({}, label)
    history.pushState(null, null);
    this.activeContainer = this.EDIT;
  }

  updateLabel(): void {
    this.longJob = true;
    this.yubillyService.updateLabel(this.labelToEdit)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.labels[this.labelIndexToEdit] = data;
        this.activeContainer = this.LIST;
        this.longJob = false;
        history.back();
      }, error => {
        this.longJob = false;
      }
      );
  }

  cancelEdit(): void {
    this.activeContainer = this.LIST;
    history.back();
  }

  onLabelRemove(content: string, labelId: number, i: number): void {
    // Are you sure?
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig.openV2(content).then((result: any) => {
      this.isModalSelectionActive = false;
      this.yubillyService.removeLabel(labelId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.labels.splice(i, 1);
        });
    }).catch((reason: any) => {
      if (reason !== 'back') {
        this.isModalSelectionActive = false;
      }
    });
  }

  ngOnDestroy(): any {
    // with takeUntil method, subscriptions are destroyed automatically,
    // however in case of any interruption during subscription
    // we unsubscribe when this component destroyed as well.
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
