import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-config',
  templateUrl: './modal-config.component.html',
  styleUrls: ['./modal-config.component.css'],
  providers: [NgbModalConfig, NgbModal]
})

@Injectable({
  providedIn: 'root'
})

export class ModalConfigComponent implements OnInit {

  constructor(private config: NgbModalConfig, private modalService: NgbModal) {
    // customize default values
    config.backdrop = 'static';
    config.keyboard = true;
    config.centered = true;
    config.size = 'sm';
  }

  ngOnInit(): void {
  }

  open(content: string): void {
    this.modalService.open(content);
  }

  openV2(content: any): any {
    const promise = new Promise((resolve, reject) => {
      this.modalService.open(content, { centered: true, size: 'sm' }).result.then((result) => {
        resolve(result);
      }, (dismiss) => {
        reject(dismiss);
      });
    });
    return promise;
  }

  closeAll(reason: string): void {
    this.modalService.dismissAll(reason);
  }

}
