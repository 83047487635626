import { Offer } from './../../shared/yubilly-data';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { YubillyService } from '@app/shared/yubilly.service';
import { TranslateService } from '@ngx-translate/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit, OnDestroy {
  longJob = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  offers: Offer[] = [];
  imageToDisplay;
  currentLanguage = 'en';
  iconSpinner = faSpinner;
  currentOriginIsTest = false;
  yourOfferHere = 'https://s3.ca-central-1.amazonaws.com/offer.yubilly/your-offer-here-' + this.currentLanguage + '.jpg';

  constructor(
    private yubillyService: YubillyService,
    private translate: TranslateService) {
    this.currentOriginIsTest = (location.origin.includes('localhost') || location.origin.includes('app1test'));
  }

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.yourOfferHere = 'https://s3.ca-central-1.amazonaws.com/offer.yubilly/your-offer-here-' + this.currentLanguage + '.jpg';
    this.translate.onLangChange.subscribe(() => this.changeCurrentLanguage());
    this.getOffersWithImageLinks(1, 10);
  }

  getOffersWithImageLinks(page: number, pageSize: number): void {
    this.longJob = true;

    this.yubillyService
      .getOffersWithImageLinks(page, pageSize)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          console.log(data);
          this.offers = data;
          this.longJob = false;
        },
        (error) => {
          this.longJob = false;
        }
      );
  }

  gotoOfferCount(offerId: number) {
    // console.log(offerLinkString);
    // setTimeout(() => window.open(offerLinkString, '_blank'), 1000);
    //this.longJob = true;
    this.yubillyService
      .countOfferLink(offerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          //console.log(data);
          //window.open(data.offerLink, '_blank');
          //setTimeout(() => window.open(data.offerLink, '_blank'), 1000);
          //this.longJob = false;
        },
        (error) => {
          //this.longJob = false;
        }
      );
  }

  changeCurrentLanguage(): void {
    this.currentLanguage = this.translate.currentLang;
    this.yourOfferHere = 'https://s3.ca-central-1.amazonaws.com/offer.yubilly/your-offer-here-' + this.currentLanguage + '.jpg';
  }

  getYourOfferHereFile(): void {
    this.longJob = true;
    this.yubillyService.getYourOfferHereFile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.createImageFromBlob(data);
        /*const blob = new Blob([data], { type: 'application/jpg' });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'your-offer-here' + '.jpg';
        link.click();*/
        this.longJob = false;
      }, error => {
        this.longJob = false;
        console.log(error);
      }
      );
  }
  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToDisplay = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  ngOnDestroy(): any {
    // with takeUntil method, subscriptions are destroyed automatically,
    // however in case of any interruption during subscription
    // we unsubscribe when this component destroyed as well.
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
