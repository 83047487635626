import { FileInfo } from './yubilly-data';
import { Membership, ClientOfferShort, ClientOfferToAddEdit } from './client-data';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ClientWithOffers } from '@app/shared/client-data';
import { ClientOffer } from '@app/shared/client-data';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private baseUrl = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService) {
  }

  getClientWithOffers(): Observable<ClientWithOffers[]> {
    return this.httpClient.get<ClientWithOffers[]>(`${this.baseUrl}/clients/offers`, {
    });
  }

  getClientWithOffersByStatusCode(statusCode: string): Observable<ClientWithOffers[]> {
    if (statusCode === 'ACTIVE') {
      return this.httpClient.get<ClientWithOffers[]>(`${this.baseUrl}/clients/offers/active`, {
      });
    } else if (statusCode === 'EXPIRED') {
      return this.httpClient.get<ClientWithOffers[]>(`${this.baseUrl}/clients/offers/expiredOrTerminated`, {
      });
    } else {
      return this.httpClient.get<ClientWithOffers[]>(`${this.baseUrl}/clients/offers/pending`, {
      });
    }
  }

  getClientOffer(offerId: number): Observable<ClientOffer> {
    return this.httpClient.get<ClientOffer>(`${this.baseUrl}/clients/offers/${offerId}`, {
    });
  }

  terminateOffer(offerId: number): Observable<ClientOfferShort> {
    return this.httpClient.put<ClientOfferShort>(
      `${this.baseUrl}/clients/offers/${offerId}/terminate`, {
    });
  }

  /*addNewOffer(offer: ClientOfferToAddEdit): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/clients/offers`, offer, {});
  }*/

  updateOffer(offerId: number, offer: ClientOfferToAddEdit): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/clients/offers/${offerId}`, offer, {});
  }

  repostOffer(offerId: number, offer: ClientOfferToAddEdit): Observable<any> {
    return this.httpClient.put<any>(
      `${this.baseUrl}/clients/offers/${offerId}/repost`, offer, {});
  }

  addNewOfferV2(offer: ClientOfferToAddEdit): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/clients/offers`, offer, {});
  }

  requestReceiptUpload(receiptFile: FileInfo): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/receipts/uploadRequest`, receiptFile, {});
  }

  uploadfileToAWSS3(signedUrl: string, file: File): Observable<any> {
    const req = new HttpRequest('PUT', signedUrl, file,
      {
        reportProgress: true, // to track upload process
      });
    return this.httpClient.request(req);
  }

  uploadSmallImage(offerId: number, formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/clients/offers/${offerId}/smallImage`, formData, {});
  }

  uploadSmallImageV2(offerId: number, body: any, headers: HttpHeaders): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/clients/offers/${offerId}/smallImage`, body, { headers });
  }
  submitMembershipApplication(membership: Membership): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/clients/membership`, membership, {
      /*params: new HttpParams()
      .set('language', this.translate.currentLang),*/ });
  }
}
