<div class="mx-auto" style="background-color: #fff; max-width: 700px; margin-top: 80px; align-content: center;">
  <h2>{{'uploadReceipt' | translate}} </h2>
  <input type="file" accept="image/jpg, image/jpeg, image/png, application/pdf, application/xml"
    (change)="selectFile($event)">
  <p></p>
  <div class="container-sm">
    <div class="row">
      <div class="col">
        <button (click)="uploadFile()"  class="btn btn-block btn-primary btn-sm"
          [disabled]="uploadDone || uploading || !receiptFile">{{'send'|translate}}</button>
      </div>
    </div>
  </div>
  <p *ngIf="uploading" style="text-align: center;">
    <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
  </p>
  <!--<img [(src)]="receiptImage" *ngIf="receiptImage">-->
  <canvas id="canvas" width="320" height="240"></canvas>
</div>
