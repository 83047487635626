import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import Auth from '@aws-amplify/auth/';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private router: Router, /*private authService: AuthService*/) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {

    return Auth.currentAuthenticatedUser().then((user) => {
      // temporary part
      // console.log("Auth.currentAuthenticatedUser():\n"
      // + user.username + ",\n"
      // + user.signInUserSession.accessToken.payload["cognito:groups"] + ",\n "
      // + user.attributes.sub  + ", "
      // + user.attributes.name  + ", "
      // + user.attributes.family_name  + ", "
      // + user.attributes.email  + ", "
      // + user.attributes.email_verified);
      // this.authService.setAppUser(true, user.attributes.email_verified,
      //  user.username, user.attributes.name, user.attributes.family_name, user.attributes.email);
      return true;
    })
      .catch(() => {
        // this.authService.setAppUser(false, null, null, null, null, null);
        this.router.navigate(['sign-in'], { queryParams: { returnUrl: state.url } });
        return false;
      });
  }
}
