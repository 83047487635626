import { ModalConfigComponent } from './../modal-config/modal-config.component';
import { Receipt, Merchant, MerchantForFilter, Label, MyCurrency, Period, Total } from './../../shared/yubilly-data';
import { YubillyService } from './../../shared/yubilly.service';
import { Component, OnInit } from '@angular/core';
import {
  faEnvelope, faReceipt, faRedo, faTemperatureLow, faTimesCircle, faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatePipe, PlatformLocation } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-recycle',
  templateUrl: './recycle.component.html',
  styleUrls: ['./recycle.component.css']
})
export class RecycleComponent implements OnInit {
  isContainerListActive = true;
  isModalSelectionActive = false;
  location: PlatformLocation;
  filtersShow = '';
  //
  pageSize = 25;
  pageNumber = 1;
  lastPage = false;
  longJob = false;
  longJobFilters = false;
  //
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentLanguage = 'en';
  downloadEnabled = true;
  iconEmail = faEnvelope;
  iconReceipt = faReceipt;
  iconRefresh = faRedo;
  iconResetFilters = faTimesCircle;
  iconSpinner = faSpinner;
  datePipe: DatePipe = new DatePipe('en-US');
  //
  receiptsRecycled: Receipt[] = [];
  merchants: Merchant[] = [];
  merchantsForFilter: MerchantForFilter[] = [];
  labels: Label[] = [];
  myCurrencyCodes: MyCurrency[] = [];
  periods: Period[] = [];
  totals: Total[] = [];
  fileName = '';
  //
  selectedPeriod: Period = {
    periodId: 0,
    period: ''

  };
  selectedLabel: Label = {
    labelId: 0,
    labelName: 'all',
    labelColor: '',
    inUse: false
  };
  selectedMerchant: MerchantForFilter = {
    merchantId: 0,
    merchantName: ''
  };
  selectedCurrency: MyCurrency = {
    currencyCode: 'all'
  };
  receiptToEdit: Receipt;
  receiptRecycledIndexToEdit: number;
  //

  constructor(
    private translate: TranslateService,
    private yubillyService: YubillyService,
    private modalConfig: ModalConfigComponent) { }

  ngOnInit(): void {
    this.getRecycles();
  }

  getRecycles(): void {
    this.longJob = true;
    this.yubillyService
      .getReceiptsRecycledWithFilters()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // console.log(data[0]);
        this.receiptsRecycled = data[0].receipts;
        this.merchantsForFilter = data[0].merchants;
        this.labels = data[0].labels;
        this.periods = data[0].periods;
        this.myCurrencyCodes = data[0].currencies;
        this.totals = data[0].totals;
        this.longJob = false;
        this.selectedMerchant = this.merchantsForFilter[0];
        this.selectedLabel = this.labels[0];
        this.selectedPeriod = this.periods[0];
        this.selectedCurrency = this.myCurrencyCodes[0];
      },
        (error) => {
          this.longJob = false;
        }
      );
  }

  showHideFilters(): void {
    // console.log('this.filtersShow1:'+this.filtersShow);
    if (this.filtersShow === '') {
      this.filtersShow = 'show';
    } else {
      this.filtersShow = '';
    }
    localStorage.setItem('yubilly.filtersShow', this.filtersShow);
    // console.log('this.filtersShow2:'+this.filtersShow);
  }

  resetFilters(): void {
    this.selectedMerchant = this.merchantsForFilter[0];
    this.selectedLabel = this.labels[0];
    this.selectedPeriod = this.periods[0];
    this.selectedCurrency = this.myCurrencyCodes[0];
    this.refreshReceiptsRecycled(true);
  }

  onReceiptRecycledUpdate(receipt: Receipt): any {
    this.receiptRecycledIndexToEdit = this.receiptsRecycled.indexOf(receipt);
    this.receiptToEdit = Object.assign({}, receipt);
    //
    history.pushState(null, null);
    this.isContainerListActive = false;
  }

  getReceiptFile2(receipt: Receipt): void {
    receipt.downloadingReceipt = true;
    this.downloadEnabled = false;
    this.yubillyService
      .getReceiptFile(receipt.receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download =
            receipt.merchantName +
            this.datePipe.transform(receipt.invoiceDate, '_yyyyMMdd') +
            '.pdf';
          link.click();
          this.downloadEnabled = true;
          receipt.downloadingReceipt = false;
        },
        (error) => {
          receipt.downloadingReceipt = false;
        }
      );
  }

  getReceiptEmail2(receipt: Receipt): void {
    receipt.downloadingEmail = true;
    this.downloadEnabled = false;
    this.yubillyService
      .getReceiptEmail(receipt.receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          const blob = new Blob([data], { type: 'application/eml' });
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download =
            receipt.merchantName +
            this.datePipe.transform(receipt.invoiceDate, '_yyyyMMdd') +
            '.eml';
          // this.datePipe.transform(receipt.emailTime, '_yyyyMMdd_HHmmss') +
          link.click();
          this.downloadEnabled = true;
          receipt.downloadingEmail = false;
        },
        (error) => {
          receipt.downloadingEmail = false;
        }
      );
  }
  exportData(content: string): void {
    // console.log('===Receipt List to Export===');
    // console.log(document.getElementById('pageTitle').innerText);
    const fileNameStartsWith = document.getElementById('pageTitle').innerText.trim();
    this.fileName = fileNameStartsWith.replace(/\s/g, '_')
      + this.datePipe.transform(new Date(), '_yyyyMMdd_HHmmss');
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig.openV2(content).then((result: any) => {
      if (this.fileName === '') {
        this.fileName = fileNameStartsWith
          + this.datePipe.transform(new Date(), '_yyyyMMdd_HHmmss');
      }
      const wb = new Workbook();
      const ws = wb.addWorksheet(fileNameStartsWith.toString());
      // console.log(this.translate.parser('merchant'));
      this.translate.get(['date', 'merchant', 'label', 'total', 'currency']).subscribe((res) => {
        // console.log(res);
        ws.columns = [
          { header: res.date, key: 'invoiceDate', width: 15 },
          { header: res.merchant, key: 'merchantName', width: 30 },
          { header: res.label, key: 'labelName', width: 12 },
          { header: res.total, key: 'total', width: 12 },
          { header: res.currency, key: 'currency', width: 12 },
        ];
        // ws.addRows(this.receipts, 'n');
        this.receiptsRecycled.forEach(e => {
          ws.addRow({
            invoiceDate: new Date(e.invoiceDate),
            merchantName: e.merchantName,
            labelName: e.labelName,
            total: Number(e.total) === 0 ? '' : Number(e.total),
            currency: e.currency
          }, 'n');
        });
        ws.getColumn('invoiceDate').alignment = { horizontal: 'left' };
        ws.getColumn('total').alignment = { horizontal: 'right' };
        ws.getColumn('currency').alignment = { horizontal: 'center' };
        ws.getColumn('total').numFmt = '#,##0.00;[Red]\-#,##0.00';

        wb.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          // { type: 'text/csv' }
          fs.saveAs(blob, (this.fileName + '.xlsx'));
        });
        this.isModalSelectionActive = false;
      },
        (error) => {
          console.log(error);
          this.isModalSelectionActive = false;
        }
      );
    }).catch((reason: any) => {
      if (reason !== 'back') {
        this.isModalSelectionActive = false;
      }
    });
  }
  restoreReceiptInRecycleBin(receiptId: number): void {
    this.longJob = true;
    this.yubillyService
      .restoreReceiptInRecycleBin(receiptId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.receiptsRecycled.splice(this.receiptRecycledIndexToEdit, 1);
          this.longJob = false;
          this.isContainerListActive = true;
          history.back();
        },
        (error) => {
          this.longJob = false;
        }
      );
  }

  deleteReceiptInRecycleBin(content: string, receiptId: number): void {
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig.openV2(content).then((result: any) => {
      this.isModalSelectionActive = false;
      this.longJob = true;
      this.yubillyService
        .deleteReceiptInRecycleBin(receiptId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data) => {
            this.receiptsRecycled.splice(this.receiptRecycledIndexToEdit, 1);
            this.longJob = false;
            this.isContainerListActive = true;
            history.back();
            history.back();
          },
          (error) => {
            this.longJob = false;
          }
        );
    }).catch((reason: any) => {
      if (reason !== 'back') {
        this.isModalSelectionActive = false;
      }
    });
  }

  refreshReceiptsRecycled(fromFilters: boolean): void {
    if (fromFilters) {
      this.longJobFilters = true;
    } else {
      this.longJob = true;
    }
    this.yubillyService
      .getReceiptsRecycledWithTotals(
        this.selectedMerchant.merchantName,
        this.selectedLabel.labelId,
        this.selectedPeriod.periodId,
        this.selectedCurrency.currencyCode
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.receiptsRecycled = data[0].receipts;
          this.totals = data[0].totals;
          if (fromFilters) {
            this.longJobFilters = false;
          } else {
            this.longJob = false;
          }
        },
        (error) => {
          if (fromFilters) {
            this.longJobFilters = false;
          } else {
            this.longJob = false;
          }
        }
      );
  }

  backToList(): void {
    this.isContainerListActive = true;
    history.back();
  }

  onPreviousPage(): void {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      this.lastPage = false;
    }
  }

  onNextPage(): void {
    if (!this.lastPage) {
      this.pageNumber = this.pageNumber + 1;
    }
  }
}
