import { Component, OnInit, Pipe, Injectable, PipeTransform, OnDestroy } from '@angular/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { YubillyService } from '@app/shared/yubilly.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Currency } from '@app/shared/yubilly-data';
import { ModalConfigComponent } from '../modal-config/modal-config.component';
import { PlatformLocation } from '@angular/common';


@Pipe({
  name: 'currencyFilter'
})
@Injectable()
export class CurrencyFilter implements PipeTransform {
  transform(currencies: Currency[], arg1: boolean): any {
    return currencies.filter(currency => currency.selected === arg1);
  }
}


@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.css']
})
export class CurrenciesComponent implements OnInit, OnDestroy {
  isModalSelectionActive = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  longJob = false;
  currencies: Currency[] = [];

  iconRemove = faTrash;
  currencyFilter = CurrencyFilter;

  constructor(
    private location: PlatformLocation,
    private yubillyService: YubillyService,
    private modalConfig: ModalConfigComponent
  ) { }

  ngOnInit(): void {
    this.location.onPopState(() => {
      if (this.isModalSelectionActive) {
        this.modalConfig.closeAll('back')
      }
    });
    this.getCurrencies();
  }

  getCurrencies(): void {
    this.longJob = true;
    this.yubillyService.getCurrencyCodes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currencies = data;
        this.longJob = false;
      }, error => {
        this.longJob = false;
      }
      );
  }

  /* getMyCurrencies(): void {
    this.longJob = true;
    this.yubillyService.getMyCurrencyCodes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.myCurrencies = data;
        this.longJob = false;
      }, error => {
        this.longJob = false;
      }
      );
  }*/

  addNewCurrency(contentCurrencySelectionTemplate: string): void {
    if (this.currencies.length <= 0) {
      this.longJob = true;
      this.yubillyService.getCurrencyCodes()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          this.currencies = result;
          this.longJob = false;
        }, error => {
        this.longJob = false;
      }
      );
    }
    history.pushState(null, null);
    this.isModalSelectionActive = true;
    this.modalConfig.openV2(contentCurrencySelectionTemplate).then(() => {
      history.back();
      this.isModalSelectionActive = false;
    }).catch((reason) => {
      if (reason !== 'back') {
        history.back();
        this.isModalSelectionActive = false;
      }
    });
  }

  selectCurrency(currency: Currency): void {
    this.longJob = true;
    this.yubillyService.addCurrency(currency)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.currencies = result;
        this.longJob = false;
      }, error => {
        this.longJob = false;
      }
      );
  }

  removeCurrency(currencyId: number): void {
    this.longJob = true;
    this.yubillyService.removeCurrency(currencyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.currencies = result;
        this.longJob = false;
      }, error => {
        this.longJob = false;
      }
      );
  }

  ngOnDestroy(): any {
    // with takeUntil method, subscriptions are destroyed automatically,
    // however in case of any interruption during subscription
    // we unsubscribe when this component destroyed as well.
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
