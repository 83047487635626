import { ReCaptchaV3Service } from 'ng-recaptcha';
// import { RecaptchaErrorParameters, RecaptchaModule, ReCaptchaV3Service } from 'ng-recaptcha';
import { NgRecaptcha3Service } from "ng-recaptcha3";
import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import Auth from '@aws-amplify/auth/';
import { AuthService } from '@app/components/auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalConfigComponent } from '@app/components/modal-config/modal-config.component';
import { TranslateService } from '@ngx-translate/core';

Auth.configure({
  region: 'ca-central-1',
  userPoolId: 'ca-central-1_3gTyT6x3i',
  userPoolWebClientId: '3cjr74c5dkc4iig1rtfim0u0h3',
  authenticationFlowType: 'USER_PASSWORD_AUTH'
});


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit, OnDestroy {
  newPassword = '';
  reEnteredPassword = '';
  reEnteredPasswordError = '';
  //
  returnUrl: string;
  forwardedUsername = '';
  ymail: string;
  user: any; // used only for 'new password required' at first login.
  //
  sigInFormGroup: FormGroup;
  emailFormGroup: FormGroup;
  ymailFormGroup: FormGroup;
  passwordRecoveryFormGroup: FormGroup;
  newPasswordFormGroup: FormGroup;
  //
  loading: boolean;
  error: string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  private emailValidators = [
    Validators.maxLength(100),
    Validators.minLength(5),
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
  ];
  private passwordValidators = [
    Validators.maxLength(100),
    Validators.minLength(8)
  ];
  private verificationCodeValidators = [
    Validators.pattern('[0-9]{6,6}$')
  ];

  constructor(
    private renderer: Renderer2,
    private recaptchaV3Service: ReCaptchaV3Service,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalConfig: ModalConfigComponent,
    private fb: FormBuilder) { };

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'recaptcha');
    if (this.authService.isUserSignedIn()) {
      this.router.navigateByUrl('/');
    } else {
      this.forwardedUsername = this.route.snapshot.queryParams['ymail'] || '';
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '.';
    }

    this.sigInFormGroup = this.fb.group({
      password: [''],
      userName: this.forwardedUsername
    })
    this.sigInFormGroup.get('password').setValidators(this.passwordValidators.concat(Validators.required));
    this.sigInFormGroup.get('userName').setValidators(this.emailValidators.concat(Validators.required));
    //
    this.emailFormGroup = this.fb.group({
      email: ['']
    })
    this.emailFormGroup.get('email').setValidators(this.emailValidators.concat(Validators.required));
    //
    this.ymailFormGroup = this.fb.group({
      ymail: ['']
    })
    this.ymailFormGroup.get('ymail').setValidators(this.emailValidators.concat(Validators.required));
    //
    this.passwordRecoveryFormGroup = this.fb.group({
      verificationCode: [''],
      newPassword: [''],
      reEnteredPassword: ['']
    }, { validator: this.test })
    this.passwordRecoveryFormGroup.get('verificationCode').setValidators(this.verificationCodeValidators.concat(Validators.required));
    this.passwordRecoveryFormGroup.get('newPassword').setValidators(this.passwordValidators.concat(Validators.required));
    this.passwordRecoveryFormGroup.get('reEnteredPassword').setValidators(Validators.required);
    //
    this.newPasswordFormGroup = this.fb.group({
      newPassword: [''],
      reEnteredPassword: ['']
    }, { validator: this.test })
    this.newPasswordFormGroup.get('newPassword').setValidators(this.passwordValidators.concat(Validators.required));
    this.newPasswordFormGroup.get('reEnteredPassword').setValidators(Validators.required);
  };

  private test(control: AbstractControl): ValidationErrors | null {
    const x = control.get('newPassword').value;
    const y = control.get('reEnteredPassword').value;
    return (x !== y) ? { matchError: true } : null;
  }

  async loginWithCognito(contentNewPasswordRequired: string) {
    this.loading = true;
    Auth.signIn(this.sigInFormGroup.value.userName,
      this.sigInFormGroup.value.password).then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // open password change form
          this.user = user;
          this.newPassword = '';
          this.reEnteredPassword = '';
          this.loading = false;
          this.modalConfig.open(contentNewPasswordRequired);
        } else {
          // console.log('User authenticated');
          // console.log(user.signInUserSession.accessToken.payload['cognito:groups']);
          this.authService.setAppUser(
            true,
            user.attributes.email_verified,
            user.username,
            user.attributes.name,
            user.attributes.family_name,
            user.attributes.email,
            user.signInUserSession.accessToken.payload['cognito:groups']);
          Auth.currentAuthenticatedUser({
            // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            bypassCache: false
          });
          this.loading = false;
          if (this.forwardedUsername !== '') {
            this.router.navigateByUrl('/support');
          } else {
            this.router.navigateByUrl(this.returnUrl);
          }
        }
      }).catch(error => {
        this.authService.setAppUser(false, null, null, null, null, null, '');
        /*{code: "NotAuthorizedException", name: "NotAuthorizedException", message: "Incorrect username or password."}*/
        // console.log(error);
        this.loading = false;
        window.alert(((error.code === undefined) ? '' : (error.code + '\n'))
          .concat((error.message === undefined) ? '' : error.message));
      });
  }

  openModalForm(content: string): void {
    this.modalConfig.open(content);
  }

  completeNewPassword(): void {
    this.loading = true;
    // console.log(this.user);
    Auth.completeNewPassword(this.user, this.newPasswordFormGroup.value.newPassword
      // {name: this.user.attributes.name, family_name: this.user.attributes.family_name}
      // OPTIONAL, the required attributes
    ).then(user => {
      this.modalConfig.closeAll('ok'); // Cannot read property 'email_verified' of undefined
      // at this point the user needs to re-login goto sign in
      // this.authService.setAppUser(true, user.attributes.email_verified,
      // user.username, user.attributes.name, user.attributes.family_name, user.attributes.email);
      this.loading = false;
      this.router.navigateByUrl('/sign-in');
    }).catch(err => {
      this.loading = false;
      alert(err.message);
    });
  }

  submitEmailForUsernameRecovery(): void {
    this.loading = true;
    this.recaptchaV3Service.execute('USERNAME_RECOVERY').subscribe((token: string) => {
      // console.log(`Token [${token}] generated`);
      if (token !== null) {
        this.authService.forgotUserName(this.emailFormGroup.value.email, token)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.modalConfig.closeAll('ok');
            this.loading = false;
          }, error => {
            this.error = error;
            this.loading = false;
          });
      } else {
        // console.log(`Token [${token}] generated`);
        this.modalConfig.closeAll('ok');
        this.error = 'TOKEN_ERROR';
        this.loading = false;
      }
    }, error => {
      this.error = error;
      this.loading = false;
    }
    );

  }

  submitUsernameForPasswordRecovery(contentNewPasswordSubmitForm: string): void {
    this.loading = true;
    this.authService.forgotPasswordRequireReset(this.ymailFormGroup.value.ymail)
      .then(() => {
        this.ymail = this.ymailFormGroup.value.ymail;
        this.modalConfig.closeAll('ok');
        this.newPassword = '';
        this.reEnteredPassword = '';
        this.loading = false;
        this.modalConfig.open(contentNewPasswordSubmitForm);
      }).catch(err => {
        // {code: "UserNotFoundException", name: "UserNotFoundException", message: "Username/client id combination not found."}
        // err.code='ExpiredCodeException', err.name='ExpiredCodeException', err.message
        this.loading = false;
        alert(err.code + '\n' + err.message);
      });
  }

  submitNewPasswordWithVerificationCode(): void {
    this.loading = true;
    this.authService.forgotPasswordSubmitWithVerificationCode(
      this.ymail, this.passwordRecoveryFormGroup.value.verificationCode, this.passwordRecoveryFormGroup.value.newPassword)
      .then(() => {
        this.newPassword = '';
        this.reEnteredPassword = '';
        this.modalConfig.closeAll('ok');
        this.loading = false;
        // alert('Sign in with your new Pasword');
      }).catch(err => {
        this.newPassword = '';
        this.reEnteredPassword = '';
        // console.log(err);
        // err.code='ExpiredCodeException', err.name='ExpiredCodeException', err.message
        this.loading = false;
        alert(err.code + '\n' + err.message);
      });
  }

  ngOnDestroy(): any {
    // with takeUntil method, subscriptions are destroyed automatically,
    // however in case of any interruption during subscription
    // we unsubscribe when this component destroyed as well.
    // this.recaptcha3.destroy();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.renderer.removeClass(document.body, 'recaptcha');
  }
}
