<div style="background-color: #fff; margin-right: 25px; margin-top: 80px; align-content: center;">
  <h2 class="page-title">{{'profile' | translate}}</h2>
  <form class="yubilly-form" #profileForm="ngForm">
    <div class="form-group">
      <label for="userName">{{'userName' | translate}}</label>
      <div class="form-control" id="userName">{{getUserName()}}</div>
    </div>
    <div class="form-group">
      <label for="firstName">{{'name' | translate}}</label>
      <fa-icon [icon]="iconEdit" style="margin-left: 10px; font-size:18px; color:black;" type="button"
        (click)="openNameUpdate(contentNameUpdate)"></fa-icon>
      <div class="form-control" id="firstName">{{firstName}}</div>
    </div>
    <div class="form-group">
      <label for="lastName">{{'lastName' | translate}}</label>
      <div class="form-control" id="lastName">{{lastName}}</div>
    </div>
    <div class="form-group">
      <label for="email">{{'recoveryEmail' | translate}}</label>
      <span class="text-danger font-weight-bold" *ngIf="emailVerified">
        <fa-icon [icon]="iconEdit" style="margin-left: 10px; font-size:18px; color:black;" type="button"
          (click)="onEmailUpdate(contentNewEmail)"></fa-icon>
      </span>
      <span class="text-danger font-weight-bold" *ngIf="!emailVerified"> [{{'notVerified' | translate}}]
        <button type="button" class="btn btn-info btn-sm" style="margin-left: 10px;"
          (click)="openVerificationCodeSubmitForm(contentVerificationCode)">{{'verify'|translate}}
        </button>
      </span>
      <div class="form-control" id="email">{{[(email)]}}</div>
    </div>

    <div class="form-group">
      <button class="btn btn-info btn-sm" (click)="onPasswordChange(contentPasswordChange)"
        *ngIf="emailVerified">{{'changePassword'|translate}}
      </button>
    </div>
  </form>

  <ng-template #contentNameUpdate let-c="close" let-d="dismiss">

    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'nameUpdate'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!--<div [hidden]="commons.nameUpdateViewHidden">-->
    <form class="yubilly-form" [formGroup]="nameFormGroup" (ngSubmit)="onNameUpdateSubmitV2(nameUpdateForm)"
      #nameUpdateForm="ngForm">
      <div class="card border border-5 border-success">
        <div class="card-body">
          <!--<h5 class="card-title">{{'nameUpdate'|translate}}</h5>-->
          <div class="form-group">
            <label for="firstNameInput">{{'name' | translate}}</label>
            <input ngbAutofocus type="text" class="form-control" id="firstNameInput" name="firstNameInput"
              [(ngModel)]="firstName" formControlName="firstName" required
              [ngClass]="{'input-error':nameFormGroup.controls.firstName.invalid && nameFormGroup.controls.firstName.touched}">
          </div>
          <div class="form-group">
            <label for="lastNameInput">{{'lastName' | translate}}</label>
            <input type="text" class="form-control" id="lastNameInput" name="lastNameInput" [(ngModel)]="lastName"
              formControlName="lastName" required
              [ngClass]="{'input-error':nameFormGroup.controls.lastName.invalid && nameFormGroup.controls.lastName.touched}">
          </div>

          <div class="container-sm">
            <div class="row">
              <div class="col">
                <button type="submit" [disabled]="nameUpdateForm.invalid  || loading"
                  class="btn btn-block btn-primary btn-sm">{{'save'|translate}}
                </button>
              </div>
              <div class="col">
                <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
                  {{'cancel'|translate}}
                </button>
              </div>
            </div>
          </div>
          <p></p>
          <p></p>

        </div>
      </div>
    </form>
    <!--</div>-->
  </ng-template>
  <!---->
  <ng-template #contentNewEmail let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'emailUpdate'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form class="yubilly-form" (ngSubmit)="onEmailUpdateSubmit(newEmail, contentVerificationCode)"
      [formGroup]="newEmailFormGroup" #emailUpdateForm="ngForm">
      <div class="modal-body">
        <div class="form-group">
          <label for="emailInput">{{'newEmailAddress' | translate}}</label>
          <input ngbAutofocus required type="email" class="form-control" formControlName="newEmail" id="emailInput"
            name="emailInput" [(ngModel)]="newEmail"
            [ngClass]="{'input-error':newEmailFormGroup.controls.newEmail.invalid && newEmailFormGroup.controls.newEmail.touched}">
        </div>
      </div>

      <div class="container-sm">
        <div class="row">
          <div class="col">
            <button type="submit" class="btn btn-block btn-primary btn-sm"
              [disabled]="emailUpdateForm.invalid || loading">{{'ok'|translate}}</button>
          </div>
          <div class="col">
            <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
              {{'cancel'|translate}}
            </button>
          </div>
        </div>
      </div>
      <p></p>
      <p></p>
    </form>
  </ng-template>
  <!---->
  <ng-template #contentVerificationCode let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'verificationCodeSubmit'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form class="yubilly-form" (ngSubmit)="submitEmailVerificationCode(verificationCodeForm)"
      #verificationCodeForm="ngForm">
      <div class="modal-body">
        <div>{{'verificationCodeSentToNewEmail' | translate}} <br /> {{'checkYourEmail' | translate}}</div>
        <br />
        <div class="form-group">
          <label for="verificationCode">{{'enterVerificationCode' | translate}}</label>
          <input ngbAutofocus required pattern="[0-9]{6,6}$" type="text" class="form-control" id="verificationCode"
            name="verificationCode" [(ngModel)]="verificationCode">
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button class="btn btn-block btn-primary btn-sm" type="submit"
                [disabled]="verificationCodeForm.invalid || loading">{{'ok'|translate}}</button>
            </div>
            <div class="col">
              <button class="btn btn-block btn-secondary btn-sm" type="button"
                (click)="d('cancel')">{{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>
        <p></p>
      </div>
    </form>
  </ng-template>

  <ng-template #contentPasswordChange let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'changePassword'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="onPasswordChangeCancel(); d('CrossClick')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form class="yubilly-form" (ngSubmit)="submitNewPassword()" #passwordChangeForm="ngForm">
      <div class="modal-body">
        <div class="form-group">
          <label for="password">{{'enterYourCurrentPassword'|translate}}</label>
          <input ngbAutofocus class="form-control" [class.is-invalid]="validatePassword(password)" type="password"
            name="password" [(ngModel)]="password" required id="password">
        </div>
        <div class="form-group">
          <label for="newPassword">{{'enterYourNewPassword'|translate}}</label>
          <input class="form-control" [class.is-invalid]="validateNewPassword(newPassword)" type="password"
            name="newPassword" [(ngModel)]="newPassword" required id="newPassword">
          <small><span class="text-danger"
              *ngIf="newPasswordError">{{'newPasswordMustBeDifferent'|translate}}</span></small>
        </div>
        <div class="form-group">
          <label for="password">{{'reEnterYourNewPassword'|translate}}</label>
          <input class="form-control" [class.is-invalid]="validateReEnteredPassword(reEnteredPassword)" type="password"
            name="reEnteredPassword" [(ngModel)]="reEnteredPassword" required id="reEnteredPassword">
          <small><span class="text-danger"
              *ngIf="reEnteredPasswordError">{{'reEnteredPasswordNotSame'|translate}}</span></small>
        </div>
      </div>
      <div class="container-sm">
        <div class="row">
          <div class="col">
            <button type="submit" class="btn btn-block btn-primary btn-sm"
              [disabled]="passwordChangeForm.invalid  || loading || validateForm()">{{'ok'|translate}}</button>
          </div>
          <div class="col">
            <button type="button" (click)="onPasswordChangeCancel(); d('cancel')"
              class="btn btn-block btn-secondary btn-sm">{{'cancel'|translate}}
            </button>
          </div>
        </div>
      </div>
      <p></p>
      <p></p>

    </form>

  </ng-template>
</div>
