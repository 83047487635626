import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//
import { AuthInterceptorService } from './shared/auth-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { ReceiptListComponent } from './components/receipts/receipt-list.component';
import { OffersComponent } from './components/offers/offers.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { I18nModule } from './i18n/i18n.module';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { SignOutComponent } from './components/auth/sign-out/sign-out.component';
import { AppPasswordConfirmComponent } from './components/auth/password-verification/password-verification.component';
import { PasswordVerificationComponent } from './components/auth/password-verification/password-verification.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgxCaptchaModule } from 'ngx-captcha';
import { environment } from '../environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RECAPTCHA_LANGUAGE, RecaptchaV3Module } from 'ng-recaptcha';
import { ModalConfigComponent } from './components/modal-config/modal-config.component';
import { MerchantsComponent } from './components/merchants/merchants.component';
import { LabelsComponent } from './components/labels/labels.component';
import { CurrenciesComponent, CurrencyFilter } from './components/currencies/currencies.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { ModalConfirmationService } from './components/modal-confirmation/modal-confirmation.service';
import { HttpErrorInterceptor } from './shared/http-error-interceptor'
// import { AmountDirective } from './shared/amount.directive';
import { SupportComponent } from './components/support/support.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ClientsComponent } from './components/clients/clients.component';
import { ClientOffersComponent } from './components/client-offers/client-offers.component';
import { MembershipComponent } from './components/membership/membership.component';
import { RecycleComponent } from './components/recycle/recycle.component';
import { OthersComponent } from './components/others/others.component';
import { DatePipe } from '@angular/common';
import { UploadComponent } from './components/upload/upload.component';

@NgModule({
  declarations: [
    AppComponent,
    AppPasswordConfirmComponent,
    PasswordVerificationComponent,
    HeaderComponent,
    FooterComponent,
    ReceiptListComponent,
    OffersComponent,
    HomeComponent,
    SelectLanguageComponent,
    SignInComponent,
    SignUpComponent,
    ProfileComponent,
    SignOutComponent,
    ModalConfigComponent,
    MerchantsComponent,
    LabelsComponent,
    CurrenciesComponent,
    CurrencyFilter,
    ModalConfirmationComponent,

    SupportComponent,
    ClientsComponent,
    ClientOffersComponent,
    MembershipComponent,
    RecycleComponent,
    OthersComponent,
    UploadComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    NgbModule,
    NgxQRCodeModule,
    RecaptchaV3Module,
    NgxCaptchaModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  entryComponents: [
    AppPasswordConfirmComponent,
    PasswordVerificationComponent
  ],
  providers: [
    // {provide: [LocationStrategy], useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: DatePipe },
    { provide: ModalConfirmationService },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKeyV3,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
