import { Component, OnInit, Renderer2} from '@angular/core';
import { AuthService } from '@app/components/auth/auth.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // ymailUser: Boolean;

  constructor(
    private authService: AuthService,
    private renderer2: Renderer2,
    /*@Inject(DOCUMENT) private document: Document*/) {
  }

  ngOnInit(): void {
    /*const script = this.renderer2.createElement('script');
    script.text = `
            $(".navbar-collapse a").click(function(){
            $(".navbar-collapse").collapse("hide");
        });
        `;
    this.renderer2.appendChild(this.document.body, script);
    */
    //
    // this.ymailUser = false;
    this.authService.verifyUserStatus();
  }

  isUserSignedIn(): boolean {
    return this.authService.isUserSignedIn();
  }

  isUserMerchantUser(): boolean {
    return this.authService.isUserMerchantUser();
  }

  getUserName(): string {
    return this.authService.getUserName();
  }
  signOut(): void {
    this.authService.signOut();
  }
  closeNavMenu(): void{
  // document.getElementById('closeMe').click();
  }
}
