<div class="mx-auto" style="background-color: #fff; max-width: 700px; margin-top: 80px; align-content: center;">
  <ng-container *ngIf="activeContainer==LIST">
    <h2 class="page-title">{{'merchants' | translate}}</h2>

    <div *ngIf="longJob" class="d-flex spinner-border" role="status"></div>
    <div class="list-group">
      <div class="row" style="max-width: 700px;">
        <div class="col-sm">
          <div class="table-sm">
            <table class="table table-striped table-sm" style="max-width: 700px;">
              <thead>
                <tr>
                  <th class="merchant" style="text-align:left">{{'merchantName'|translate}}</th>
                  <th class="email" style="text-align:left">{{'emailFrom'|translate}}</th>
                  <th class="currency" style="text-align:center">{{'currencyShort'|translate}}</th>
                  <th class="in-use" style="text-align:center">{{'inUse'|translate}}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let merchant of merchants; index as i">
                  <td class="merchant align-left m-0" (click)="onMerchantEdit(merchant)">{{merchant.merchantName}}</td>
                  <td class="email align-left m-0" (click)="onMerchantEdit(merchant)">{{merchant.invoiceEmail}}</td>
                  <td class="currency m-0" style="text-align:center;" (click)="onMerchantEdit(merchant)">
                    {{merchant.currency}}</td>
                  <td class="in-use m-0" style="text-align:center; font-weight: bold;">
                    <span *ngIf="merchant.inUse">&#10003;</span>
                  </td>
                  <td class="merchant-button m-0" style="text-align:center">
                    <span *ngIf="!merchant.inUse">
                      <fa-icon [icon]="iconRemove" style="color:black;" type="button" rounded="true"
                        (click)="onMerchantRemove(confirmationTemplate, merchant.merchantId, i)">
                      </fa-icon>
                    </span>
                  </td>

                </tr>
              </tbody>
            </table>
            <button class="btn btn-info btn-sm" (click)="onMerchantCreate()">{{'createNewMerchant'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="activeContainer==EDIT">
    <h4 class="page-title">{{'merchantEdit'|translate}}</h4>

    <form class="yubilly-form" (ngSubmit)="updateMerchant()" #merchantUpdateForm="ngForm"
      [formGroup]="merchantEditFormGroup">
      <div class="container">

        <div class="row">
          <div class="col-12">
            <div class="form-group" style="margin-bottom: 0px;">
              <label for="merchantName">{{'merchantName'|translate}}</label>
              <input ngbAutofocus
                [ngClass]="{'input-error':merchantEditFormGroup.controls.merchantName.invalid && merchantEditFormGroup.controls.merchantName.touched}"
                class="form-control" formControlName="merchantName" type="text" name="merchantName"
                [(ngModel)]="merchantToEdit.merchantName" required id="merchantName">
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 0px; margin-bottom: 14px;"><div class="col"><span style="font-size: smaller;">
          [{{merchantToEdit.invoiceEmail}}]</span></div></div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="currency">{{'currency'|translate}}</label>

              <select style = "margin-left: 10px;" [(ngModel)]="merchantToEdit.currency" formControlName="currency" name="currency" id="currency">
                <option *ngFor="let currency of myCurrencyCodes" [ngValue]="currency.currencyCode"
                  [selected]="currency.currencyCode==merchantToEdit.currency">
                  {{currency.currencyCode}}
                </option>
              </select>

              <!--<input class="form-control" formControlName="currency" type="text" name="currency" id="currency"
                [(ngModel)]="merchantToEdit.currency">-->
            </div>
          </div>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-block btn-primary btn-sm"
                [disabled]="merchantUpdateForm.invalid">{{'save'|translate}}</button>
            </div>
            <div class="col">
              <button type="button" (click)="cancelEdit()" class="btn btn-block btn-secondary btn-sm">
                {{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>

      </div>
    </form>

  </ng-container>
  <ng-container *ngIf="activeContainer==ADD">
    <h4 class="page-title">{{'newMerchant'|translate}}</h4>
    <form class="yubilly-form" (ngSubmit)="addMerchant(merchantAddForm.value)" #merchantAddForm="ngForm"
      [formGroup]="merchantAddFormGroup">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="merchantName">{{'merchantName'|translate}}</label>
              <input ngbAutofocus
                [ngClass]="{'input-error':merchantAddFormGroup.controls.merchantName.invalid && merchantAddFormGroup.controls.merchantName.touched}"
                class="form-control" formControlName="merchantName" id="merchantName" ngModel required>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="currency">{{'currency'|translate}}</label>
              <br />
              <select ngModel formControlName="currency" name="currency" id="currency">
                <option *ngFor="let currency of myCurrencyCodes" [ngValue]="currency">
                  <span *ngIf="currency.currencyCode!='allCurrencies'">{{currency.currencyCode}}</span>
                </option>
              </select>
              <!--<input class="form-control" formControlName="currency" type="text" name="currency" id="currency" ngModel="">-->
            </div>
          </div>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button type="submit" class="btn btn-block btn-primary btn-sm"
                [disabled]="merchantAddForm.invalid">{{'add'|translate}}</button>
            </div>
            <div class="col">
              <button type="button" (click)="cancelCreate()" class="btn btn-block btn-secondary btn-sm">
                {{'cancel'|translate}}</button>
            </div>
          </div>
        </div>
        <p></p>

      </div>
    </form>

  </ng-container>

  <ng-template #confirmationTemplate let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'pleaseConfirm'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{'areYouSureToRemove'|translate}}
    </div>

    <div class="container-sm">
      <div class="row">
        <div class="col">
          <button type="submit" (click)="c('ok')" class="btn btn-block btn-danger btn-sm">{{'ok'|translate}}</button>
        </div>
        <div class="col">
          <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
            {{'cancel'|translate}}</button>
        </div>
      </div>
    </div>
    <p></p>

  </ng-template>

</div>
