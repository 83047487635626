<div class="mx-auto" style="background-color: #fff; max-width: 700px; margin-top: 80px; align-content: center;">
  <ng-container *ngIf="activeContainer==LIST">
    <h2 id="pageTitle" class="page-title" *ngIf="!longJob" (click)="getClientWithOffers()" style="cursor: pointer;">
      {{client.clientName}} {{'offers' | translate}}
    </h2>
    <h2 class="page-title" *ngIf="longJob">
      <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
    </h2>
    <div class="row">
      <div class="col" style="vertical-align: center; font-weight: bold;">
        <select class="form-control form-control-sm" [(ngModel)]="selectedStatus" (change)="refreshClientOffers()">
          <option *ngFor="let item of offerStatusList" [ngValue]="item.statusCode">
            {{item.status | translate}}
            <!--<span *ngIf="label.labelId==0"><b>{{label.labelName|translate}}</b></span>
            <span *ngIf="label.labelId!=0">{{label.labelName}}</span>-->
          </option>
        </select>
      </div>
      <div class="col">
        <button type="button" class="btn btn-block btn-primary btn-sm" style="vertical-align: center;"
          (click)="openOfferAdd()">
          {{ 'newOffer' | translate }}
        </button>
      </div>
    </div>
    <br />
    <div class="table-sm" style="margin-top: 0%;">
      <table class="table table-striped table-sm" style="margin-top: 0%;">
        <tbody>
          <tr *ngFor="let offer of clientOffers; index as i">
            <td class="align-middle m-0" style="border-top:1px solid black;border-bottom:1px solid black;">
              {{'offerName'|translate}}: <b>{{offer.offerName}}</b><br />
              {{'postedOn'|translate}}: {{offer.createTime | date:'yyyy-MM-dd HH:mm'}}<br />
              {{'validFrom'|translate}}: {{offer.validFrom | date:'yyyy-MM-dd HH:mm'}}<br />
              {{'validUntil'|translate}}: {{offer.validUntil | date:'yyyy-MM-dd HH:mm'}}<br />
              {{'status'|translate}}:
              <span *ngIf="offer.offerStatusId === 0" style="font-weight:bold; color: rgba(224, 168, 0);">
                {{offer.offerStatus}}
                <button type="button" class="btn btn-warning btn-sm" style="margin-left: 0.5rem; margin-right: 0.5rem;"
                  [disabled]="terminating" (click)="openOfferEdit(offer.offerId, i)">{{ 'edit' | translate }}</button>
                <span *ngIf="!terminating">
                  <button type="button" class="btn btn-danger btn-sm" [disabled]="terminating"
                    (click)="terminateOffer(confirmationTemplate, offer, i)">{{ 'terminate' | translate }}</button>
                </span>
                <span *ngIf="terminating">
                  <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black;" rounded="true"></fa-icon>
                </span>
              </span>
              <span *ngIf="offer.offerStatusId === 1" style="font-weight:bold; color: darkgreen;">
                {{offer.offerStatus}}
                <button type="button" class="btn btn-warning btn-sm" style="margin-left: 0.5rem; margin-right: 0.5rem;"
                  [disabled]="terminating" (click)="openOfferEdit(offer.offerId, i)">{{ 'edit' | translate }}</button>
                <!--<button type="button" class="btn btn-info btn-sm" style="margin-left: 0.5rem; margin-right: 0.5rem;"
                  [disabled]="terminating" (click)="openOfferView(offer.offerId)">{{ 'view' | translate }}</button>
                -->
                <span *ngIf="!terminating">
                  <button type="button" class="btn btn-danger btn-sm" [disabled]="terminating"
                    (click)="terminateOffer(confirmationTemplate, offer, i)">{{ 'terminate' | translate }}</button>
                </span>
                <span *ngIf="terminating">
                  <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black;" rounded="true"></fa-icon>
                </span>

              </span>
              <span *ngIf="offer.offerStatusId > 1" style="font-weight:bold; color: rgba(100, 103, 104, 0.671);">
                {{offer.offerStatus}}
                <button type="button" class="btn btn-info btn-sm" style="margin-left: 0.5rem; margin-right: 0.5rem;"
                  [disabled]="terminating" (click)="openOfferView(offer.offerId)">{{ 'view' | translate }}</button>
                <button type="button" class="btn btn-warning btn-sm" style="margin-left: 0.5rem; margin-right: 0.5rem;"
                  [disabled]="terminating" (click)="openOfferRepost(offer.offerId, i)">{{ 'repost' | translate }}
                </button>
              </span>
              <br />
            </td>
          </tr>
        </tbody>

      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="activeContainer==ADD">
    <h2>{{'newOffer' | translate}} </h2>
    <form class="yubilly-form" (ngSubmit)="addOfferV2(offerAddForm.value)" #offerAddForm="ngForm"
      [formGroup]="offerAddFormGroup">

      <label for="offerName">{{'offerName'|translate}}</label>
      <input ngbAutofocus [ngClass]="{'input-error':offerAddFormGroup.controls.offerName.invalid
                && offerAddFormGroup.controls.offerName.touched}" class="form-control" formControlName="offerName"
        id="offerName" [(ngModel)]="offerName" required>

      <div class="card offer">
        <!--<label for="header">{{'header'|translate}}</label>-->
        <div class="card-header offer-header">
          <input [ngClass]="{'input-error':offerAddFormGroup.controls.header.invalid
                          && offerAddFormGroup.controls.header.touched}" class="form-control font-weight-bold"
            formControlName="header" id="header" [(ngModel)]="clientOfferToAddEdit.header" required
            placeholder="{{'brand' | translate}}">
        </div>
        <!--<label for="title">{{'title'|translate}}</label>-->
        <div class="card-title">
          <input class="form-control" formControlName="title" id="title" ngModel placeholder="{{'teaser' | translate}}">
        </div>
        <!-- Image here -->
        <input class="form-control" type="file" accept="image/jpg, image/jpeg" (change)="selectFile($event)">
        <img class="card-img-top" [(src)]="offerImageSmall" *ngIf="offerImageSmall">
        <div class="card-body">
          <!--<label for="message">{{'message'|translate}}</label>-->
          <!--<input type="text" class="form-control" formControlName="message" id="message" ngModel>-->
          <textarea rows="3" class="form-control" style="border:1px solid black;" formControlName="message" id="message"
            ngModel placeholder="{{'message' | translate}}"> </textarea>
        </div>
      </div>
      <!--end of card-->
      <label for="offerLink">{{'offerLink'|translate}}</label>
      <input type="text" class="form-control" formControlName="offerLink" id="offerLink" ngModel>
      <label for="validFrom">{{'validFrom'|translate}}</label>
      <input type="datetime-local" [ngClass]="{'input-error':offerAddFormGroup.controls.validFrom.invalid
                          && offerAddFormGroup.controls.validFrom.touched}" class="form-control"
        formControlName="validFrom" id="validFrom" [(ngModel)]="clientOfferToAddEdit.validFrom" required>

      <label for="validUntil">{{'validUntil'|translate}}</label>
      <input type="datetime-local" [ngClass]="{'input-error':offerAddFormGroup.controls.validUntil.invalid
                          && offerAddFormGroup.controls.validUntil.touched}" class="form-control"
        formControlName="validUntil" id="validUntil" [(ngModel)]="clientOfferToAddEdit.validUntil" required>

      <p></p>
      <div class="container-sm">
        <div class="row">
          <div class="col">
            <button class="btn btn-block btn-primary btn-sm"
              [disabled]="offerAddFormGroup.invalid  || uploading">{{'submit'|translate}}</button>
          </div>
          <div class="col">
            <button type="button" (click)="cancelCreate()" class="btn btn-block btn-secondary btn-sm"
              [disabled]="uploading"> {{'cancel'|translate}}</button>
          </div>
        </div>
      </div>
      <p *ngIf="uploading" style="text-align: center;">
        <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
      </p>
    </form>
    <p></p>
  </ng-container>

  <ng-container *ngIf="activeContainer==EDIT">
    <h2>{{'editOffer' | translate}} </h2>
    <p *ngIf="longJob" style="text-align: center;">
      <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
    </p>

    <form class="yubilly-form" (ngSubmit)="updateOffer(offerEditForm.value)" #offerEditForm="ngForm"
      [formGroup]="offerEditFormGroup">
      <label for="offerName">{{'offerName'|translate}}</label>
      <input ngbAutofocus [ngClass]="{'input-error':offerEditFormGroup.controls.offerName.invalid
                && offerEditFormGroup.controls.offerName.touched}" class="form-control" formControlName="offerName"
        id="offerName" [(ngModel)]="clientOfferToAddEdit.offerName" required>

      <div class="card offer">
        <div class="card-header offer-header">
          <input [ngClass]="{'input-error':offerEditFormGroup.controls.header.invalid
                          && offerEditFormGroup.controls.header.touched}" class="form-control font-weight-bold"
            formControlName="header" id="header" [(ngModel)]="clientOfferToAddEdit.header" required
            placeholder="{{'header' | translate}}">
        </div>

        <div class="card-title">
          <input class="form-control" formControlName="title" id="title" [(ngModel)]="clientOfferToAddEdit.title"
            placeholder="{{'title' | translate}}">
        </div>
        <input class="form-control" type="file" accept="image/jpg, image/jpeg" (change)="selectFile($event)">
        <span *ngIf="offerImageSmall">
          <img class="card-img-top" [(src)]="offerImageSmall">
        </span>
        <span *ngIf="!offerImageSmall">
          <img class="card-img-bottom" [(src)]="clientOfferToAddEdit.signedSmallImageUrl">
        </span>
        <div class="card-body">
          <textarea rows="3" class="form-control" style="border:1px solid black;" formControlName="message" id="message"
            [(ngModel)]="clientOfferToAddEdit.message" placeholder="{{'message' | translate}}"> </textarea>
        </div>
      </div>
      <label for="offerLink">{{'offerLink'|translate}}</label>
      <input class="form-control" formControlName="offerLink" id="offerLink"
        ngModel="{{clientOfferToAddEdit.offerLink}}">
      <!--<p>
        <label>{{'postedOn'|translate}}</label>: {{clientOfferToAddEdit.createTime | date:'yyyy-MM-dd HH:mm'}}<br />
      </p>-->
      <label for="validFrom">{{'validFrom'|translate}}</label>
      <input type="datetime-local" [ngClass]="{'input-error':offerEditFormGroup.controls.validFrom.invalid
                          && offerEditFormGroup.controls.validFrom.touched}" class="form-control"
        formControlName="validFrom" id="editValidFrom"
        ngModel="{{clientOfferToAddEdit.validFrom | date:'yyyy-MM-ddTHH:mm'}}" required>
      <label for="validUntil">{{'validUntil'|translate}}</label>
      <input type="datetime-local" [ngClass]="{'input-error':offerEditFormGroup.controls.validUntil.invalid
                          && offerEditFormGroup.controls.validUntil.touched}" class="form-control"
        formControlName="validUntil" id="editValidUntil"
        ngModel="{{clientOfferToAddEdit.validUntil | date:'yyyy-MM-ddTHH:mm'}}" required>
      <p></p>
      <div class="container-sm">
        <div class="row">
          <div class="col">
            <button class="btn btn-block btn-primary btn-sm"
              [disabled]="offerEditFormGroup.invalid  || uploading">{{'update'|translate}}</button>
          </div>
          <div class="col">
            <button type="button" (click)="cancelEdit()" class="btn btn-block btn-secondary btn-sm"
              [disabled]="uploading">{{'cancel'|translate}}</button>
          </div>
        </div>
      </div>
      <p *ngIf="uploading" style="text-align: center;">
        <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
      </p>
    </form>
    <p></p>
  </ng-container>

  <ng-container *ngIf="activeContainer==REPOST">
    <h2>{{'repost' | translate}} </h2>
    <p *ngIf="longJob" style="text-align: center;">
      <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
    </p>

    <form class="yubilly-form" (ngSubmit)="repostOffer(offerEditForm.value)" #offerEditForm="ngForm"
      [formGroup]="offerEditFormGroup">
      <label for="offerName">{{'offerName'|translate}}</label>
      <input disabled class="form-control" formControlName="offerName"
        id="offerName" [(ngModel)]="clientOfferToAddEdit.offerName">

      <div class="card offer">
        <div class="card-header offer-header">
          <input disabled class="form-control font-weight-bold" formControlName="header"
          id="header" [(ngModel)]="clientOfferToAddEdit.header">
        </div>

        <div class="card-title">
          <input disabled class="form-control" formControlName="title"
          id="title" [(ngModel)]="clientOfferToAddEdit.title">
        </div>
        <span *ngIf="!offerImageSmall">
          <img class="card-img-bottom" [(src)]="clientOfferToAddEdit.signedSmallImageUrl">
        </span>
        <div class="card-body">
          <textarea disabled rows="3" class="form-control" style="border:1px solid black;" formControlName="message"
          id="message" [(ngModel)]="clientOfferToAddEdit.message"> </textarea>
        </div>
      </div>
      <label for="offerLink">{{'offerLink'|translate}}</label>
      <input disabled class="form-control" formControlName="offerLink"
        id="offerLink" ngModel="{{clientOfferToAddEdit.offerLink}}">
      <b><label for="validFrom">{{'validFrom'|translate}}</label></b>
      <input type="datetime-local" [ngClass]="{'input-error':offerEditFormGroup.controls.validFrom.invalid
                          && offerEditFormGroup.controls.validFrom.touched}" class="form-control"
        formControlName="validFrom" id="editValidFrom"
        ngModel="{{clientOfferToAddEdit.validFrom | date:'yyyy-MM-ddTHH:mm'}}" required>
      <b><label for="validUntil">{{'validUntil'|translate}}</label></b>
      <input type="datetime-local" [ngClass]="{'input-error':offerEditFormGroup.controls.validUntil.invalid
                          && offerEditFormGroup.controls.validUntil.touched}" class="form-control"
        formControlName="validUntil" id="editValidUntil"
        ngModel="{{clientOfferToAddEdit.validUntil | date:'yyyy-MM-ddTHH:mm'}}" required>
      <p></p>
      <div class="container-sm">
        <div class="row">
          <div class="col">
            <button class="btn btn-block btn-primary btn-sm"
              [disabled]="offerEditFormGroup.invalid  || uploading">{{'submit'|translate}}</button>
          </div>
          <div class="col">
            <button type="button" (click)="cancelEdit()" class="btn btn-block btn-secondary btn-sm"
              [disabled]="uploading">{{'cancel'|translate}}</button>
          </div>
        </div>
      </div>
      <p *ngIf="uploading" style="text-align: center;">
        <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
      </p>
    </form>
    <p></p>
  </ng-container>

  <ng-container *ngIf="activeContainer==VIEW">
    <h2>{{'offer' | translate}} </h2>
    <p *ngIf="longJob" style="text-align: center;">
      <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black; font-size:32px;" rounded="true"></fa-icon>
    </p>

    <label>{{'offerName'|translate}}</label>: <b>{{clientOffer.offerName}}</b>
    <br /><br />
    <div class="card offer">
      <div class="card-header offer-header">{{clientOffer.header}}</div>
      <div class="card-title">{{clientOffer.title}}</div>
      <a [(href)]="clientOffer.offerLink" target="_blank">
        <img class="card-img-bottom" alt="{{clientOffer.header}}" [(src)]="clientOffer.signedSmallImageUrl">
      </a>
      <div class="card-body">{{clientOffer.message}}</div>
    </div>
    <label>{{'offerLink'|translate}}</label>: {{clientOffer.offerLink}}<br />
    <label>{{'postedOn'|translate}}</label>: {{clientOffer.createTime | date:'yyyy-MM-dd HH:mm'}}<br />
    <label>{{'validFrom'|translate}}</label>: {{clientOffer.validFrom | date:'yyyy-MM-dd HH:mm'}}<br />
    <label>{{'validUntil'|translate}}</label>: {{clientOffer.validUntil | date:'yyyy-MM-dd HH:mm'}}
    <p></p>
    <div class="container-sm">
      <div class="row">
        <div class="col">
          <button type="button" (click)="cancelCreate()" class="btn btn-block btn-secondary btn-sm">
            {{'returnToList'|translate}}</button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #confirmationTemplate let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'pleaseConfirm'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>{{'thisOfferWillBeTerminated' | translate}}</label>
    </div>
    <div class="container-sm">
      <div class="row">
        <div class="col">
          <button type="submit" (click)="c('ok')" class="btn btn-block btn-primary btn-sm">{{'ok'|translate}}</button>
        </div>
        <div class="col">
          <button type="button" (click)="d('cancel')" class="btn btn-block btn-secondary btn-sm">
            {{'cancel'|translate}}</button>
        </div>
      </div>
    </div>
    <p></p>
  </ng-template>
</div>
