import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { YMailOption } from '@app/shared/yubilly-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})

export class SignUpComponent implements OnInit, OnDestroy {
  protected aFormGroup: FormGroup;
  currentLanguage = 'en';

  captchaResolved = false;
  protected captchaResponse = '';
  protected siteKey = environment.recaptchaNotRobot.siteKeyNotRobot;
  //
  agreed = false;
  step1Visible = true;
  step2Visible = false;
  step3Visible = false;
  signUpFormGroup: FormGroup;
  pwFormGroup: FormGroup;

  destroy$: Subject<boolean> = new Subject<boolean>();
  yMailOptions: YMailOption[];
  selectedYMail: YMailOption;

  loading: boolean;
  error: string;

  private emailValidators = [
    Validators.maxLength(100),
    Validators.minLength(5),
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
  ];

  /*letters only
    first name can include (one space OR one dot) between names
    last name is one word only, so no space no dot.*/
  letters = '[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœĞÜŞİÖÇğüöşı]'
  //   ^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$
  private nameValidators = [
    Validators.maxLength(40),
    Validators.minLength(3),
    Validators.pattern('^(' + this.letters + '+[ \.])+' + this.letters + '+[\.]?' + '$|^' + this.letters + '+$')
  ];

  private lastNameValidators = [
    Validators.maxLength(40),
    Validators.minLength(3),
    Validators.pattern('^' + this.letters + '+$')
  ];
  private verificationCodeValidators = [
    Validators.pattern('[0-9]{6,6}$')
  ];
  private passwordValidators = [
    Validators.maxLength(100),
    Validators.minLength(8)
  ];
  constructor(
    private renderer: Renderer2,
    private recaptchaV3Service: ReCaptchaV3Service,
    private translate: TranslateService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router) {

  }

  ngOnInit(): void {
    // this.renderer.addClass(document.body, 'recaptcha');
    //
    this.captchaResolved = false;
    this.signUpFormGroup = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      verificationCode: ['']
    })
    this.signUpFormGroup.get('firstName').setValidators(this.nameValidators.concat(Validators.required));
    this.signUpFormGroup.get('lastName').setValidators(this.lastNameValidators.concat(Validators.required));
    this.signUpFormGroup.get('email').setValidators(this.emailValidators.concat(Validators.required));
    //
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    this.pwFormGroup = this.formBuilder.group({
      password: [''],
      reEnteredPassword: ['']
    })
    this.pwFormGroup.get('password').setValidators(this.passwordValidators.concat(Validators.required));
    this.pwFormGroup.get('reEnteredPassword').setValidators(Validators.required);
    //
    this.currentLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => this.changeRecaptchaLanguage());
  }

  changeRecaptchaLanguage(): void {
    this.currentLanguage = this.translate.currentLang;
  }

  handleReset(): void {
    this.captchaResolved = false;
    this.captchaResponse = '';
  }
  handleExpire(): void {
    this.captchaResolved = false;
    this.captchaResponse = '';
  }
  handleLoad(): void {
    this.captchaResolved = false;
    this.captchaResponse = '';
  }
  handleSuccess(captchaResponse: string): void {
    this.captchaResolved = true;
    this.captchaResponse = captchaResponse;
    // console.log(captchaResponse);
  }

  agreeChecked(): void {
    this.agreed = (document.getElementById('agree') as HTMLInputElement).checked;
  }

  requestVerificationCode(form: NgModel): void {
    this.loading = true;
    const x: string = form.value.firstName;
    x.trim().replace(/\s+$/, '');
    form.value.firstName = x;
    this.authService.requestVerificationCodeForSignUp(form.value.email, this.captchaResponse)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = false;
        // this.renderer.addClass(document.body, 'recaptcha');
        this.goToStep2();
      }, error => {
        this.error = error;
        this.loading = false;
      }
      );
  }

  submitVerificationCode(form: NgModel): void {
    // if cancel goto step1
    // console.log('recaptchaV3Service.execute will run ...');
    this.loading = true;
    /* this.recaptchaV3Service.execute('SIGN_UP_VERIFICATION').subscribe((token: string) => {
      console.log(`Token for SIGN_UP_VERIFICATION [${token}] generated`);
      if (token !== null) {*/
        this.authService.submitVerificationCodeForSignUp(
          this.signUpFormGroup.get('email').value,
          this.signUpFormGroup.get('verificationCode').value,
          this.signUpFormGroup.get('firstName').value,
          this.signUpFormGroup.get('lastName').value,
          ''
        ).pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.yMailOptions = data;
            this.selectedYMail = this.yMailOptions[0];
            this.loading = false;
            this.goToStep3();
          }, (error) => {
            this.error = error;
            this.loading = false;
          }
          );
      /* } else {
        this.error = 'TOKEN_ERROR';
        this.loading = false;
      }
    }, error => {
      this.error = error;
      this.loading = false;
    }
    );*/
  }

  submitNewUser(): void {
    // console.log('selectedYMail:' + this.selectedYMail.ymail);
    // if everything is ok, goto signin
    // if cancel goto step1
    this.loading = true;

    /*this.recaptchaV3Service.execute('SIGN_UP').subscribe((token: string) => {
      // console.log(`Token for SIGN_UP [${token}] generated`);
      if (token !== null) {*/
        const newUser = {
          firstName: this.signUpFormGroup.get('firstName').value,
          lastName: this.signUpFormGroup.get('lastName').value,
          email: this.signUpFormGroup.get('email').value,
          verificationCode: this.signUpFormGroup.get('verificationCode').value,
          ymail: this.selectedYMail.ymail,
          pw: this.pwFormGroup.get('password').value
          // recaptcha: ''
        };
        this.authService.submitNewUser(newUser)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.loading = false;
            this.router.navigateByUrl('/sign-in?ymail=' + this.selectedYMail.ymail);
          }, (error) => {
            this.error = error;
            this.loading = false;
          }
          );
      /*} else {
        this.error = 'TOKEN_ERROR';
        this.loading = false;
      }
    }, error => {
      this.error = error;
      this.loading = false;
    }
    );*/
  }

  cancelNewUser(): void {
    this.router.navigateByUrl('/sign-in');
  }
  goToStep1(): void {
    this.step1Visible = true;
    this.step2Visible = false;
    this.step3Visible = false;
  }
  goToStep2(): void {
    this.signUpFormGroup.controls.firstName.disable();
    this.signUpFormGroup.controls.lastName.disable();
    this.signUpFormGroup.controls.email.disable();
    this.step1Visible = false;
    this.step2Visible = true;
    this.step3Visible = false;
  }
  goToStep3(): void {
    this.step1Visible = false;
    this.step2Visible = false;
    this.step3Visible = true;
  }

  ngOnDestroy(): any {
    // with takeUntil method, subscriptions are destroyed automatically,
    // however in case of any interruption during subscription
    // we unsubscribe when this component destroyed as well.
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.renderer.removeClass(document.body, 'recaptcha');
  }
}

