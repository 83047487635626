<nav class="navbar navbar-expand bg-dark navbar-dark fixed-top">
  <div class="container" style="max-width: 700px; align-content: center;">
    <a class="navbar-brand" href="/">YubillY</a>

    <div class="d-flex ml-auto">
      <ul class="navbar-nav flex-row">
        <li class="nav-item">
          <a class="nav-link" routerLink="/receipts">{{'receipts' | translate}}</a>
        </li>
        <li class="nav-item" style="margin-left: 0.1rem;">
          <a class="nav-link" routerLink="/offers">{{'offers' | translate}}</a>
        </li>

        <li class="nav-item dropdown" style="margin-left: 0.1rem;">
          <a class="nav-link" href="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            {{'settings'|translate}}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <span *ngIf="!isUserMerchantUser()">
              <a class="dropdown-item" routerLink="/membership">{{'beAMemberMerchant' | translate}}</a>
              <div class="dropdown-divider"></div>
            </span>
            <span *ngIf="isUserMerchantUser()">
              <a class="dropdown-item" routerLink="/manage-offers">{{'manageOffers' | translate}}</a>
              <div class="dropdown-divider"></div>
            </span>
            <a class="dropdown-item" routerLink="/upload">{{'uploadReceipt' | translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/merchants">{{'merchants' | translate}}</a>
            <a class="dropdown-item" routerLink="/labels">{{'labels' | translate}}</a>
            <a class="dropdown-item" routerLink="/currencies">{{'currencies' | translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/profile">{{'profile' | translate}}</a>
            <a class="dropdown-item" routerLink="/support">{{'support' | translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/recycles">{{'recycleBin' | translate}}</a>
            <a class="dropdown-item" routerLink="/others">{{'junk' | translate}}</a>
            <div class="dropdown-divider"></div>
            <div *ngIf="isUserSignedIn()">
              <a class="dropdown-item" routerLink="/sign-out" (click)="signOut();">{{'signOut' | translate}}</a>
            </div>
            <!--<div *ngIf="!isUserSignedIn()">
              <a class="dropdown-item" routerLink="/sign-in">{{'signIn' | translate}}</a>
            </div>-->
          </div>
        </li>

        <li class="nav-item"
          style="margin-left: 0.1rem; margin-right: 0.1rem; margin-top:0.8rem; padding:0rem; font-size: smaller;">
          <app-select-language></app-select-language>
        </li>
      </ul>
    </div>
  </div>
</nav>
