import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // retry(1),
        catchError((error: HttpErrorResponse) => {
          // console.log('error:' + JSON.stringify(error.error));
          // console.log('error.error.message:' + error.error.message);
          //
          const message = (error.error instanceof ErrorEvent)
            ? error.error.message
            : `StatusCode:${error.status}; Message:"${JSON.stringify(error.error)}"`;
          // error.error.message : `Status Code:${error.status}\nMessage:"${error.message}"`;

          /*const errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }*/
          window.alert(message);
          return throwError(message);
        })
      )
  }
}
