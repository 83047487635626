import { YubillyService } from './../../shared/yubilly.service';
import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})

export class SupportComponent implements OnInit {
  // currentLanguage = 'en';
  longJob = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  introduction = '';
  iconSpinner = faSpinner;

  constructor(
    private translate: TranslateService,
    private yubillyService: YubillyService
  ) { }

  ngOnInit(): void {
    // An EventEmitter to listen to lang change events
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.getIntroduction();
    });
    this.getIntroduction();
  }

  getIntroduction(): void {
    this.introduction = '';
    this.longJob = true;
    this.yubillyService
      .getIntroduction(this.translate.currentLang)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // console.log(data[0]);
        this.introduction = data.introduction;
        this.longJob = false;
      },
        (error) => {
          this.longJob = false;
        }
      );
  }

}

/*
@Component({
  selector: 'app-support-en',
  templateUrl: './support.en.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportEnComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}

@Component({
  selector: 'app-support-fr',
  templateUrl: './support.fr.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportFrComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}

@Component({
  selector: 'app-support-tr',
  templateUrl: './support.tr.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportTrComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}*/
