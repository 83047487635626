<div class="cmx-auto" style="background-color: #fff; margin-top: 80px; align-content: center;">
  <h3 class="page-title">{{'beAMemberMerchant' | translate}}</h3>
  <div class="yubilly-form" style="text-align: center; font-size: medium ;">
    {{'membershipInstruction1'|translate}}
    <br/>{{'membershipInstruction2'|translate}}
    <br/>{{'membershipInstruction3'|translate}}
  </div>
  <br/>
  <div>

    <form class="yubilly-form" [formGroup]="membershipFormGroup" #membershipForm="ngForm">

      <div class="form-group">
        <label for="clientName">{{'merchantName' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.clientName.invalid
          && membershipFormGroup.controls.clientName.touched}" class="form-control" formControlName="clientName"
          type="text" name="clientName">
      </div>
      <div class="form-group">
        <label for="address">{{'address' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.address.invalid
          && membershipFormGroup.controls.address.touched}" class="form-control" formControlName="address"
          type="text" name="address">
      </div>
      <div class="form-group">
        <label for="city">{{'city' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.city.invalid
          && membershipFormGroup.controls.city.touched}" class="form-control" formControlName="city"
          type="text" name="city">
      </div>
      <div class="form-group">
        <label for="province">{{'province' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.province.invalid
          && membershipFormGroup.controls.province.touched}" class="form-control" formControlName="province"
          type="text" name="province">
      </div>
      <div class="form-group">
        <label for="postalCode">{{'postalCode' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.postalCode.invalid
          && membershipFormGroup.controls.postalCode.touched}" class="form-control" formControlName="postalCode"
          type="text" name="postalCode">
      </div>
      <div class="form-group">
        <label for="country">{{'country' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.country.invalid
          && membershipFormGroup.controls.country.touched}" class="form-control" formControlName="country"
          type="text" name="country">
      </div>

      <p style="text-align: center; font-size: medium; font-weight: bold; margin-bottom: 0px;">
        {{'contactInformation'|translate}}</p>
      <div class="form-group">
        <label for="contactName">{{'contactName' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.contactName.invalid
          && membershipFormGroup.controls.contactName.touched}" class="form-control input-bottom"
          formControlName="contactName" type="text" name="contactName">

        <label for="tel">{{'tel' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.tel.invalid
          && membershipFormGroup.controls.tel.touched}" class="form-control" formControlName="tel"
          type="text" name="tel">

        <label for="email">{{'email' | translate}}</label>
        <input [ngClass]="{'input-error':membershipFormGroup.controls.email.invalid
          && membershipFormGroup.controls.email.touched}" class="form-control input-bottom" formControlName="email"
          type="email" name="email">

        <label for="website">{{'website' | translate}}</label>
        <input class="form-control input-bottom" formControlName="website"
            type="text" name="website">
        </div>

      <div style="margin-bottom: 0.5rem;">
        <form [formGroup]="aFormGroup">
          <ngx-recaptcha2 #captchaElem
          [siteKey]="siteKey"
          size="normal"
          [hl]="currentLanguage"
          theme="light"
          type="image"
            useGlobalDomain="true" (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
            (success)="handleSuccess($event)" formControlName="recaptcha">
          </ngx-recaptcha2>
        </form>
      </div>

      <div class="form-group">
        <button class="btn btn-block btn-primary btn-sm" (click)="submitApplication(membershipForm);"
        [disabled]="membershipFormGroup.invalid || submitting || !captchaResolved">
        {{'submit'|translate}}</button>

      </div>
    </form>
  </div>
</div>
