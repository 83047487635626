import { FileInfo } from './../../shared/yubilly-data';
import { ClientService } from './../../shared/client.service';
import { Component, OnInit } from '@angular/core';
import { faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  receiptFile: File;
  receiptImage: any;
  uploading = false;
  uploadDone = false;
  iconSpinner = faSpinner;

  constructor(private clientService: ClientService) {
  }

  ngOnInit(): void {
  }

  // On file Select
  selectFile(event): void {
    if (!event.target.files[0] || event.target.files[0].length === 0) {
      return;
    }
    this.receiptFile = event.target.files[0];
    const mimeType = this.receiptFile.type;
    // if (mimeType.match(/image\/j*/) === null) {
    if (mimeType.match('image.j*|image.png|application.pdf|application.xml')
      === null) {
      // "Only images, pdf and xml files are supported";
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.receiptFile);
    reader.onload = (event) => {
      this.receiptImage = reader.result;
      this.uploadDone = false;

      const canvas = document.getElementById('canvas');
      /*
      const context = canvas.getContext('2d');
      context.drawImage(player, 0, 0, canvas.width, canvas.height);
      */
      //////
      /*
      canvas.height = videoElem.videoHeight;
      canvas.width = videoElem.videoWidth;
      context.drawImage(videoElem, 0, 0);
      canvas.toBlob(blob=> {
          console.log(blob);
          storage.push(blob);
      });
      */
    };
    // console.log(this.receiptImage);
  }

  uploadFile(): void {
    this.uploading = true;
    //
    console.log('sending...');
    const fileInfo: FileInfo = {
      fileName: this.receiptFile.name
    };

    this.clientService.requestReceiptUpload(fileInfo)
      .subscribe(result => {
        console.log(result.preSignedUrl);
        this.clientService.uploadfileToAWSS3(result.preSignedUrl, this.receiptFile)
          .subscribe(result2 => {
            console.log(result2);
            this.uploading = false;
            this.uploadDone = true;
          }, error => {
            console.log('error:' + error);
            this.uploading = false;
            this.uploadDone = false;
          }
          );
      }, error => {
        console.log('error:' + error);
        this.uploading = false;
        this.uploadDone = false;
      });
  }

  cancelUpload(): void {
    this.uploading = false;
    this.receiptFile = null;
    this.receiptImage = null;
  }
}
