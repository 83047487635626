import { faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ModalConfigComponent } from './../modal-config/modal-config.component';
import { ClientService } from './../../shared/client.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DatePipe, PlatformLocation } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Client, ClientOfferShort, ClientOffer, ClientOfferToAddEdit, OfferStatus } from '@app/shared/client-data';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { visitLexicalEnvironment } from 'typescript';

@Component({
  selector: 'app-client-offers',
  templateUrl: './client-offers.component.html',
  styleUrls: ['./client-offers.component.css']
})
export class ClientOffersComponent implements OnInit {
  LIST = 1;
  EDIT = 2;
  ADD = 3;
  VIEW = 4;
  REPOST = 5;
  activeContainer: number;
  //
  datePipe: DatePipe = new DatePipe('en-US');
  destroy$: Subject<boolean> = new Subject<boolean>();
  isModalSelectionActive = false;
  longJob = false;
  uploading = false;
  client: Client;
  clientOffers: ClientOfferShort[];
  clientOffer: ClientOffer;
  clientOfferToAddEdit: ClientOfferToAddEdit;
  offerImageFile: File;
  offerImageSmall: any;
  iconSpinner = faSpinner;
  iconEdit = faPencilAlt;
  terminating = false;
  imageChanged = false;
  offerIndexToEdit: number;
  offerIndexToRepost: number;
  offerAddFormGroup: FormGroup;
  offerEditFormGroup: FormGroup;
  offerStatusList: OfferStatus[];
  selectedStatus: string;
  private offerNameValidator = [
    Validators.maxLength(30),
    Validators.minLength(3)
  ];
  private headerValidator = [
    Validators.maxLength(50),
    Validators.minLength(2)
  ];
  private offerLinkValidator = [
    Validators.maxLength(100),
    Validators.minLength(5)
  ];
  constructor(
    location: PlatformLocation,
    private changeDetector: ChangeDetectorRef,
    private clientService: ClientService,
    private modalConfig: ModalConfigComponent,
    private fb: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.activeContainer = this.LIST;
    this.offerStatusList = [
      { statusCode: 'PENDING', status: 'statusPending' },
      { statusCode: 'ACTIVE', status: 'statusActive' },
      { statusCode: 'EXPIRED', status: 'statusExpired' }
    ];
    this.selectedStatus = this.offerStatusList[0].statusCode;
    this.offerAddFormGroup = this.fb.group({
      offerName: '',
      header: '',
      title: '',
      message: '',
      offerLink: '',
      validFrom: new Date(),
      validUntil: new Date()
    });
    this.offerAddFormGroup.get('offerName').setValidators(this.offerNameValidator.concat(Validators.required));
    this.offerAddFormGroup.get('header').setValidators(this.headerValidator.concat(Validators.required));
    this.offerAddFormGroup.get('validFrom').setValidators(Validators.required);
    this.offerAddFormGroup.get('validUntil').setValidators(Validators.required);
    //
    this.offerEditFormGroup = this.fb.group({
      offerName: '',
      header: '',
      title: '',
      message: '',
      offerLink: '',
      validFrom: new Date(),
      validUntil: new Date()
    });
    this.offerEditFormGroup.get('offerName').setValidators(this.offerNameValidator.concat(Validators.required));
    this.offerEditFormGroup.get('header').setValidators(this.headerValidator.concat(Validators.required));
    this.offerEditFormGroup.get('validFrom').setValidators(Validators.required);
    this.offerEditFormGroup.get('validUntil').setValidators(Validators.required);

    this.getClientWithOffers('PENDING');
  }

  getClientName(): string {
    return (this.client.clientName || '');
  }

  getClientWithOffers(statusCode: string): void {
    this.longJob = true;
    this.clientService.getClientWithOffersByStatusCode(statusCode)
      // .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.client = data[0].client;
          this.clientOffers = data[0].offers;
          this.longJob = false;
        },
        (error) => {
          this.longJob = false;
        }
      );
  }

  refreshClientOffers(): void {
    this.getClientWithOffers(this.selectedStatus);
  }

  terminateOffer(content: string, offer: ClientOfferShort, i: number): void {
    // history.pushState(null, null);
    // this.isModalSelectionActive = true;
    this.modalConfig.openV2(content).then((result: any) => {
      // this.isModalSelectionActive = false;
      this.terminating = true;
      this.clientService.terminateOffer(offer.offerId)
        // .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.clientOffers[i] = data;
          // console.log(offer);
          this.terminating = false;
        });
    }).catch((reason: any) => {
      if (reason !== 'back') {
        this.terminating = false;
        // this.isModalSelectionActive = false;
      }
    });
  }

  openOfferAdd(): void {
    history.pushState(null, null);
    this.offerImageSmall = null;
    this.clientOfferToAddEdit = Object.assign({});
    this.clientOfferToAddEdit.header = this.client.clientName;
    this.clientOfferToAddEdit.validFrom = new Date();
    this.clientOfferToAddEdit.validUntil = new Date();
    this.activeContainer = this.ADD;
  }

  addOfferV2(data: any): void {
    this.uploading = true;
    const offerToAdd: ClientOfferToAddEdit = {
      clientId: this.client.clientId,
      offerId: null,
      offerName: data.offerName,
      header: data.header,
      title: data.title,
      offerLink: data.offerLink,
      message: data.message,
      createTime: new Date(),
      validFrom: new Date(data.validFrom),
      validUntil: new Date(data.validUntil),
      signedSmallImageUrl: '',
      imageChanged: false
    };
    console.log('adding...');
    this.clientService.addNewOfferV2(offerToAdd)
      .subscribe(result => {
        // console.log(result);
        const newOfferItem: ClientOfferShort = result.offer;
        // console.log(JSON.stringify(result));
        console.log(result.preSignedUrl);
        this.clientService.uploadfileToAWSS3(result.preSignedUrl, this.offerImageFile)
          .subscribe(result2 => {
            console.log(result2);
            this.activeContainer = this.LIST;
            this.uploading = false;
            history.back();
          }, error => {
            console.log('error:' + error);
            this.uploading = false;
          }
          );
        this.clientOffers.unshift(newOfferItem);
        this.changeDetector.detectChanges();
      }, error => {
        console.log('error:' + error);
        this.uploading = false;
      });
  }

  cancelCreate(): void {
    this.activeContainer = this.LIST;
    history.back();
  }

  openOfferEdit(offerIdToEdit: any, i: number): void {
    history.pushState(null, null);
    this.offerIndexToEdit = i;
    this.offerImageSmall = null;
    this.clientOfferToAddEdit = {
      clientId: this.client.clientId,
      offerId: offerIdToEdit,
      offerName: '',
      header: '',
      title: '',
      offerLink: '',
      message: '',
      createTime: new Date(),
      validFrom: new Date(),
      validUntil: new Date(),
      signedSmallImageUrl: '',
      imageChanged: false
    }
    this.activeContainer = this.EDIT;
    // console.log('offerId:' + offerIdToEdit);
    this.longJob = true;
    // get offer and display
    this.clientService.getClientOffer(offerIdToEdit)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // console.log(data);
        this.clientOfferToAddEdit.offerName = data.offerName;
        this.clientOfferToAddEdit.header = data.header;
        this.clientOfferToAddEdit.title = data.title;
        this.clientOfferToAddEdit.offerLink = data.offerLink;
        this.clientOfferToAddEdit.message = data.message;
        this.clientOfferToAddEdit.createTime = new Date(data.createTime);
        this.clientOfferToAddEdit.validFrom = new Date(data.validFrom);
        this.clientOfferToAddEdit.validUntil = new Date(data.validUntil);
        this.clientOfferToAddEdit.signedSmallImageUrl = data.signedSmallImageUrl;
        // console.log(this.clientOfferToAddEdit);
        // this.clientOffer = data;
        /*this.clientOffer.validFrom = new Date(data.validFrom);
        this.clientOffer.validUntil = new Date(data.validUntil);
        // console.log(this.clientOffer.validFrom);
        // console.log(this.clientOffer.validUntil);*/
        // this.changeDetector.detectChanges();
        this.longJob = false;
      }, error => {
        this.longJob = false;
      });
  }

  updateOffer(data: any): void {
    // return;
    this.uploading = true;
    const offerToUpdate: ClientOfferToAddEdit = {
      clientId: this.client.clientId,
      offerId: this.clientOfferToAddEdit.offerId,
      offerName: data.offerName,
      header: data.header,
      title: data.title,
      offerLink: data.offerLink,
      message: data.message,
      createTime: new Date(),
      validFrom: new Date(data.validFrom),
      validUntil: new Date(data.validUntil),
      signedSmallImageUrl: '',
      imageChanged: (this.offerImageSmall == null ? false : true)
    };

    console.log('updating...');
    this.clientService.updateOffer(offerToUpdate.offerId, offerToUpdate)
      .subscribe(result => {
        // console.log(result);
        this.clientOffers[this.offerIndexToEdit] = result.offer;
        const updatedOffer: ClientOfferShort = result.offer;
        if (offerToUpdate.imageChanged) {
          if (result.preSignedUrl !== '') {
            this.clientService.uploadfileToAWSS3(result.preSignedUrl, this.offerImageFile)
              .subscribe(result2 => {
                // console.log(result2);
                this.activeContainer = this.LIST;
                this.uploading = false;
                history.back();
              }, error => {
                console.log('error:' + error);
                this.uploading = false;
              }
              );
          }
        } else {
          this.activeContainer = this.LIST;
          this.uploading = false;
          history.back();
        }
        this.clientOffers[this.offerIndexToEdit] = updatedOffer;
        // this.changeDetector.detectChanges();
      }, error => {
        console.log('error:' + error);
        this.uploading = false;
      });
  }

  cancelEdit(): void {
    this.activeContainer = this.LIST;
    history.back();
  }

  openOfferRepost(offerIdToEdit: any, i: number): void {
    history.pushState(null, null);
    this.offerIndexToRepost = i;
    this.clientOfferToAddEdit = {
      clientId: this.client.clientId,
      offerId: offerIdToEdit,
      offerName: '',
      header: '',
      title: '',
      offerLink: '',
      message: '',
      createTime: new Date(),
      validFrom: new Date(),
      validUntil: new Date(),
      signedSmallImageUrl: '',
      imageChanged: false
    }
    this.activeContainer = this.REPOST;
    this.longJob = true;
    // get offer and display
    this.clientService.getClientOffer(offerIdToEdit)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.clientOfferToAddEdit.offerName = data.offerName;
        this.clientOfferToAddEdit.header = data.header;
        this.clientOfferToAddEdit.title = data.title;
        this.clientOfferToAddEdit.offerLink = data.offerLink;
        this.clientOfferToAddEdit.message = data.message;
        this.clientOfferToAddEdit.createTime = new Date(data.createTime);
        this.clientOfferToAddEdit.validFrom = new Date(data.validFrom);
        this.clientOfferToAddEdit.validUntil = new Date(data.validUntil);
        this.clientOfferToAddEdit.signedSmallImageUrl = data.signedSmallImageUrl;
        // console.log(this.clientOfferToAddEdit);
        this.longJob = false;
      }, error => {
        this.longJob = false;
      });
  }

  repostOffer(data: any): void {
    this.uploading = true;
    const offerToUpdate: ClientOfferToAddEdit = {
      clientId: this.client.clientId,
      offerId: this.clientOfferToAddEdit.offerId,
      offerName: data.offerName,
      header: data.header,
      title: data.title,
      offerLink: data.offerLink,
      message: data.message,
      createTime: new Date(),
      validFrom: new Date(data.validFrom),
      validUntil: new Date(data.validUntil),
      signedSmallImageUrl: '',
      imageChanged: false
    };

    console.log('submitting...');
    this.clientService.repostOffer(offerToUpdate.offerId, offerToUpdate)
      .subscribe(result => {
        this.clientOffers[this.offerIndexToRepost] = result;
        // const updatedOffer: ClientOfferShort = result.offer;
        // this.clientOffers[this.offerIndexToRepost] = updatedOffer;
        // this.changeDetector.detectChanges();
        this.activeContainer = this.LIST;
        this.uploading = false;
        history.back();
      }, error => {
        console.log('error:' + error);
        this.uploading = false;
      });

  }

  openOfferView(offerId: any): void {
    history.pushState(null, null);
    this.clientOffer = {
      offerId: 0,
      offerName: '',
      header: '',
      title: '',
      message: '',
      offerLink: '',
      validFrom: new Date(),
      validUntil: new Date(),
      createTime: new Date(),
      signedSmallImageUrl: '',
      signedMediumImageUrl: '',
      signedLargeImageUrl: '',
      offerStatusId: -1,
      offerStatusCode: '',
      offerStatus: '',
    };
    this.activeContainer = this.VIEW;
    // console.log('offerId:' + offerId);
    this.longJob = true;
    // get offer and display
    this.clientService.getClientOffer(offerId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // console.log(data)
        this.clientOffer = data;
        this.longJob = false;
      }, error => {
        this.longJob = false;
      });
  }

  cancelView(): void {
    this.activeContainer = this.LIST;
    history.back();
  }

  // On file Select
  selectFile(event): void {
    if (!event.target.files[0] || event.target.files[0].length === 0) {
      return;
    }
    this.offerImageFile = event.target.files[0];
    const mimeType = this.offerImageFile.type;
    if (mimeType.match(/image\/j*/) === null) {
      // "Only images are supported";
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.offerImageFile);
    reader.onload = (event) => {
      this.offerImageSmall = reader.result;
    }
    // console.log(this.offerImageSmall);
  }

  backToHomePage(): void {
    this.router.navigateByUrl('/');
  }
}
