<div class="cmx-auto" style="background-color: #fff; margin-top: 80px; align-content: center;">
  <!--<div [innerHtml]="getIntroduction(('selectedLanguage'|translate))"></div>-->
  <div [innerHtml]="introduction"></div>
  <div class="page-title">
    <span *ngIf="longJob" style="text-align: center; vertical-align: center; font-size: xx-large;">
      <fa-icon [icon]="iconSpinner" [spin]="true" style="color:black;" rounded="true">
      </fa-icon>
    </span>
  </div>

  <!--<app-support-tr *ngIf="('selectedLanguage'|translate)=='tr'"></app-support-tr>
  <app-support-fr *ngIf="('selectedLanguage'|translate)=='fr'"></app-support-fr>
  <app-support-en *ngIf="!'tr,fr'.includes(('selectedLanguage'|translate))"></app-support-en>-->
</div>
