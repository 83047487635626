<div class="container">

  <div style="background-color: #fff; margin-right: 25px; margin-top: 80px;">
    <h4 class="page-title">{{'yubillyAccount' | translate}}</h4>
    <h4 class="page-title">{{'signUp' | translate}}</h4>

    <form [formGroup]="signUpFormGroup" #signUpForm="ngForm">
      <div class="form-group yubilly-form">
        <label for="firstName">{{'name' | translate}}</label>
        <input ngbAutofocus
          [ngClass]="{'input-error':signUpFormGroup.controls.firstName.invalid && signUpFormGroup.controls.firstName.touched}"
          class="form-control" formControlName="firstName" type="text" name="firstName">
      </div>
      <div class="form-group yubilly-form">
        <label for="lastName">{{'lastName' | translate}}</label>
        <input
          [ngClass]="{'input-error':signUpFormGroup.controls.lastName.invalid && signUpFormGroup.controls.lastName.touched}"
          class="form-control" formControlName="lastName" type="text" name="lastName">
      </div>

      <div class="form-group yubilly-form">
        <label for="email">{{'recoveryEmail' | translate}}</label>
        <input
          [ngClass]="{'input-error':signUpFormGroup.controls.email.invalid && signUpFormGroup.controls.email.touched}"
          class="form-control" formControlName="email" type="email" name="email">
      </div>

      <ng-container *ngIf="step1Visible">

        <div class="row align-items-center justify-content-center">
          <div class="col">
            <input type="checkbox"
              style="width: 1.5rem; height: 1.5rem;  vertical-align: center; margin-left:0.9rem; margin-right: 0.5rem;"
              id="agree" (click)="agreeChecked()">
            <a href="https://www.yubilly.com/privacy-policy"><label
                style="height: 1.5rem; vertical-align: top; font-size: smaller;">
                {{'iAgreeToPrivacyPolicy'|translate}}
              </label></a>
          </div>
        </div>

        <div style="margin-bottom: 0.5rem;">
          <form [formGroup]="aFormGroup">
            <ngx-recaptcha2 #captchaElem
            [siteKey]="siteKey"
            size="normal"
            [hl]="currentLanguage"
            theme="light"
            type="image"
            useGlobalDomain="false"
              (reset)="handleReset()" (expire)="handleExpire()" (load)="handleLoad()"
              (success)="handleSuccess($event)" formControlName="recaptcha">
            </ngx-recaptcha2>
          </form>
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button class="btn btn-block btn-primary btn-sm" (click)="requestVerificationCode(signUpForm)"
                [disabled]="signUpFormGroup.invalid  || loading || !captchaResolved || !agreed">{{'verifyEmail'|translate}}</button>
            </div>
            <div class="col">
              <button class="btn btn-block btn-secondary btn-sm" type="button" (click)="cancelNewUser()">
                {{'cancel'|translate}}</button>
            </div>
            <p></p>
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="step2Visible">

        <div class="form-group yubilly-form">
          <label for="verificationCode">{{'enterVerificationCode' | translate}}</label>
          <input class="form-control" formControlName="verificationCode" type="text">
        </div>

        <div class="container-sm">
          <div class="row">
            <div class="col">
              <button class="btn btn-block btn-primary btn-sm" (click)="submitVerificationCode(signUpForm);"
                [disabled]="loading">{{'submit'|translate}}</button>
            </div>
            <div class="col">
              <button class="btn btn-block btn-secondary btn-sm" type="button" (click)="cancelNewUser()">
                {{'cancel'|translate}}</button>
            </div>
            <p></p>
          </div>
        </div>

      </ng-container>
    </form>
    <ng-container *ngIf="step3Visible">
      <div>{{'chooseYourYubillyMailAddress'|translate}}</div>
      <select class="yubilly-form" [(ngModel)]="selectedYMail">
        <option *ngFor="let yMailOption of yMailOptions" [ngValue]="yMailOption">{{yMailOption.ymail}}</option>
      </select>
      <form [formGroup]="pwFormGroup" #pwForm="ngForm">

        <div class="form-group yubilly-form">
          <label for="password">{{'createYourPassword'|translate}}</label>
          <input
            [ngClass]="{'input-error':pwFormGroup.controls.password.invalid && pwFormGroup.controls.password.touched}"
            class="form-control" type="password" name="password" [(ngModel)]="password" required id="password">
        </div>
        <div class="form-group yubilly-form">
          <label for="reEnteredPassword">{{'reEnterYourPassword'|translate}}</label>
          <input [ngClass]="{'input-error':
            (pwFormGroup.controls.reEnteredPassword.invalid || (password!==reEnteredPassword))
            && pwFormGroup.controls.reEnteredPassword.touched}" class="form-control"
            formControlName="reEnteredPassword" type="password" [(ngModel)]="reEnteredPassword">
        </div>
      </form>
      <p></p>

      <div class="container-sm">
        <div class="row">
          <div class="col">
            <button class="btn btn-block btn-primary btn-sm"
            [disabled]="pwFormGroup.invalid || (password!==reEnteredPassword) || loading"
              (click)="submitNewUser()">{{'submit'|translate}}</button>
          </div>
          <div class="col">
            <button class="btn btn-block btn-secondary btn-sm" type="button" (click)="cancelNewUser()">
              {{'cancel'|translate}}</button>
          </div>
          <p></p>
        </div>
      </div>

    </ng-container>

  </div>
</div>
