<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div class="container-sm">
  <div class="row">
    <div class="col">
      <button type="submit" class="btn btn-block btn-primary btn-sm" (click)="accept()">{{ btnOkText }}</button> </div>
    <div class="col">
      <button type="button" class="btn btn-block btn-danger btn-sm" (click)="decline()">{{ btnCancelText }}</button>
    </div>
  </div>
</div>
<p></p>