import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Component, OnInit, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { AuthService } from '@app/components/auth/auth.service';
import { NgModel, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalConfigComponent } from '@app/components/modal-config/modal-config.component';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { PasswordVerificationComponent } from '@app/components/auth/password-verification/password-verification.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})

export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  // destroy: Subject<boolean> = new Subject<boolean>();
  // used for updates
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  newEmail: string;
  verificationCode: string;
  password: '';
  newPassword: '';
  reEnteredPassword: '';
  newPasswordError = false;
  reEnteredPasswordError = false;
  //
  currentLanguage = 'en';
  iconEdit = faPencilAlt;
  destroy$: Subject<boolean> = new Subject<boolean>();
  // profileFormGroup: FormGroup;
  //
  /*letters only
    first name can include (one space OR one dot) between names
    last name is one word only, so no space no dot.*/
  letters = '[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœĞÜŞİÖÇğüöşı]'
  //   ^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$
  private nameValidators = [
    Validators.maxLength(40),
    Validators.minLength(3),
    // Validators.pattern('^(' + this.letters + '+ )+' + this.letters + '+$|^' + this.letters + '+$')
    Validators.pattern('^(' + this.letters + '+[ \.])+' + this.letters + '+[\.]?' + '$|^' + this.letters + '+$')
  ];

  private lastNameValidators = [
    Validators.maxLength(40),
    Validators.minLength(3),
    Validators.pattern('^' + this.letters + '+$')
  ];
  private emailValidators = [
    Validators.maxLength(100),
    Validators.minLength(5),
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
  ];

  nameFormGroup: FormGroup;
  newEmailFormGroup: FormGroup;

  loading: boolean;
  error: string;

  constructor(
    private renderer: Renderer2,
    private recaptchaV3Service: ReCaptchaV3Service,
    // private recaptcha3: NgRecaptcha3Service,
    private translate: TranslateService,
    private authService: AuthService,
    private passwordVerification: PasswordVerificationComponent,
    private modalConfig: ModalConfigComponent,
    private fb: FormBuilder,
    private router: Router) {
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'recaptcha');
    this.firstName = this.getFirstName();
    this.lastName = this.getLastName();
    this.email = this.getEmail();
    this.emailVerified = this.isEmailVerified();
    //
    //
    this.nameFormGroup = this.fb.group({
      firstName: [''],
      lastName: ['']
    })
    this.nameFormGroup.get('firstName').setValidators(this.nameValidators.concat(Validators.required));
    this.nameFormGroup.get('lastName').setValidators(this.lastNameValidators.concat(Validators.required));

    this.newEmailFormGroup = this.fb.group({
      newEmail: this.getEmail()
    })
    this.newEmailFormGroup.get('newEmail').setValidators(this.emailValidators.concat(Validators.required));
    this.currentLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(() => this.changeRecaptchaLanguage());
    // this.recaptcha3.init(environment.recaptcha.siteKeyV3);
  }

  changeRecaptchaLanguage(): void {
    this.currentLanguage = this.translate.currentLang;
    // 1. Search for the ReCaptcha iframe
    /*const iframeGoogleCaptcha = (document.getElementsByName('#captcha_container')).querySelector('iframe');
    // 2. Retrieve the current language
    const currentLang = iframeGoogleCaptcha.getAttribute('src').match(/hl=(.*?)&/).pop();
    // 3. Verify if the language that you want to set is different to the current one
    if (currentLang !== this.translate.currentLang) {
      // 4. If it is, change it
      iframeGoogleCaptcha.setAttribute('src',
        iframeGoogleCaptcha.getAttribute('src').replace(
          /hl=(.*?)&/,
          'hl=' + this.translate.currentLang + '&'
        )
      );
    }*/
    /*document.querySelector('.recaptcha').innerHTML = '';
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?hl=' + this.translate.currentLang;
    script.async = true;
    script.defer = true;
    document.querySelector('head').appendChild(script);
    */
    // Get GoogleCaptcha iframe
    // const iframeGoogleCaptcha = document.getElementById('#captcha_container');
    // Get language code from iframe
    // const language = iframeGoogleCaptcha.attr("src").match(/hl=(.*?)&/).pop();
    // Get selected language code from drop down
    // var selectedLanguage = $('#ddllanguageListsGoogleCaptcha').val();
    // Check if language code of element is not equal by selected language, we need to set new language code
    // if (language !== selectedLanguage) {
    // For setting new language
    // iframeGoogleCaptcha.attr("src",
    //  iframeGoogleCaptcha.attr("src").replace(/hl=(.*?)&/, 'hl=' + this.translate.currentLang + '&'));
    // }
  }

  ngAfterViewInit(): void {
  }

  validatePassword(value: string): any {
    if (typeof value === 'undefined') {
      return true;
    }
    return (value.length < 8) ? true : false;
  }
  validateNewPassword(value: string): any {
    if (typeof value === 'undefined') {
      return true;
    }
    this.newPasswordError = (this.password === value && value.length >= 8) ? true : false;
    return (this.password === value || value.length < 8) ? true : false;
  }
  validateReEnteredPassword(value: string): boolean {
    if (typeof value === 'undefined') {
      return true;
    }
    this.reEnteredPasswordError = (this.newPassword !== value && value.length >= 8) ? true : false;
    return (this.password === value || this.newPassword !== value) ? true : false;
  }

  validateForm(): boolean {
    if (typeof this.password === 'undefined'
      || typeof this.newPassword === 'undefined'
      || typeof this.reEnteredPassword === 'undefined') {
      return true;
    } else if (this.password.length < 8 || this.newPassword.length < 8 || this.reEnteredPassword.length < 8) {
      return true;
    } else if (this.password === this.newPassword) {
      return true;
    } else if (this.newPassword !== this.reEnteredPassword) {
      return true;
    }
    return false;
  }

  openNameUpdate(contentNameUpdate: any): void {
    this.loading = true;
    this.passwordVerification.open()
      .then(() => {
        this.loading = false;
        this.modalConfig.open(contentNameUpdate);
      }).catch((error) => {
        this.loading = false;
      });
  }

  onNameUpdateSubmitV2(form: NgModel): any {
    if (form.invalid) {
      return;
    }
    this.loading = true;

    this.authService.updateUserFirstAndLastNameV2(form.value.firstName, form.value.lastName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // console.log('user updated');
        this.firstName = form.value.firstName;
        this.lastName = form.value.lastName;
        this.authService.refreshNameAndLastNameAfterUpdate();
        this.modalConfig.closeAll('ok');
        this.loading = false;
      }, error => {
        // console.log(err);
        this.loading = false;
        window.alert(((error.code === undefined) ? '' : (error.code + '\n'))
          .concat((error.message === undefined) ? '' : error.message));
      });

  }

  onEmailUpdate(contentNewEmail: string): void {
    this.loading = true;
    this.passwordVerification.open()
      .then(() => {
        this.loading = false;
        this.modalConfig.open(contentNewEmail);
      }).catch((error) => {
        this.loading = false;
      });
  }

  onEmailUpdateSubmit(newEmail: string, contentVerificationCode: string): any {
    this.loading = true;
    this.recaptchaV3Service.execute('EMAIL_CHANGE').subscribe((token: string) => {
      console.log(`Token [${token}] generated`);
      if (token !== null) {
        this.authService.submitEmailUpdateRequest(newEmail, token)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.modalConfig.closeAll('ok');
            this.loading = false;
            this.modalConfig.open(contentVerificationCode);
          },
            error => {
              this.error = error;
              this.loading = false;
            }
          );
      } else {
        // console.log(`Token [${token}] generated`);
        this.modalConfig.closeAll('ok');
        this.error = 'TOKEN_ERROR';
        this.loading = false;
      }
    }, error => {
      console.log(error);
      this.error = error;
      this.loading = false;
    });
  }

  openVerificationCodeSubmitForm(contentVerificationCode: string): void {
    this.modalConfig.open(contentVerificationCode);
  }

  submitEmailVerificationCode(form: NgModel): any {
    this.loading = true;
    this.authService.updateEmail(this.newEmailFormGroup.get('newEmail').value, form.value.verificationCode)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.modalConfig.closeAll('ok');
        this.loading = false;
        this.authService.signOut();
        this.router.navigateByUrl('/sign-in');
      },
        error => {
          this.error = error;
          this.loading = false;
        }
      );
  }

  onPasswordChange(contentPasswordChange: string): void {
    this.modalConfig.open(contentPasswordChange);
  }

  submitNewPassword(): void {
    this.loading = true;
    this.authService.verifyPasswordBySignIn(this.password)
      .then(() => {
        this.authService.changePassword(this.password, this.newPassword)
          .then(() => {
            console.log('password changed');
            this.password = '';
            this.newPassword = '';
            this.reEnteredPassword = '';
            this.modalConfig.closeAll('ok');
            this.loading = false;
          }).catch((error) => {
            this.error = error;
            this.loading = false;
            alert(error.message);
          });
      }).catch((error) => {
        this.error = error;
        this.loading = false;
        alert(error.message);
      });
  }

  onPasswordChangeCancel(): void {
    this.password = '';
    this.newPassword = '';
    this.reEnteredPassword = '';
  }

  getUserName(): string {
    return this.authService.getUserName();
  }

  getFirstName(): string {
    // console.log('this.authService.getFirstName():' + this.authService.getFirstName());
    return this.authService.getFirstName();
  }
  getLastName(): string {
    return this.authService.getLastName();
  }
  getEmail(): string {
    return this.authService.getEmail();
  }
  isUserSignedIn(): boolean {
    return this.authService.isUserSignedIn();
  }
  isEmailVerified(): boolean {
    return this.authService.isEmailVerified();
  }
  ngOnDestroy(): any {
    // with takeUntil method, subscriptions are destroyed automatically,
    // however in case of any interruption during subscription
    // we unsubscribe when this component destroyed as well.
    // this.recaptcha3.destroy();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.renderer.removeClass(document.body, 'recaptcha');
  }
}
