<div class="mx-auto" style="background-color: #fff; margin-top: 80px; align-content: center;">
  <h2 class="page-title">{{'myCurrencyList' | translate}}</h2>
  <div *ngIf="longJob" class="d-flex spinner-border" role="status"></div>
  <div class="list-group">
    <div class="row">
      <div class="col-sm">
        <div class="table-sm">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th style="text-align:left">{{'currency'|translate}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let currency of currencies | currencyFilter:true">
                <td class="align-left m-0">
                  {{currency.currencyCode}}
                </td>
                <td class="align-center m-0">
                  <fa-icon [icon]="iconRemove" style="color:black;" type="button" rounded="true"
                  (click)="removeCurrency(currency.currencyId)">
                  </fa-icon>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-info btn-sm" (click)="addNewCurrency(contentCurrencySelectionTemplate)">
            {{'addNewCurrency'|translate}}
          </button>
        </div>
      </div>

    </div>
  </div>

  <ng-template #contentCurrencySelectionTemplate let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{'currencies'|translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ul>
        <li *ngFor="let currency of currencies | currencyFilter:false" class="align-middle" (click)="selectCurrency(currency); c('ok')">
          <!--<span *ngIf="currency.currencyCode=='allCurrencies'">{{'noCurrency'|translate}}</span>-->
          <span *ngIf="!currency.selected">{{currency.currencyCode}}
            <hr>
          </span>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <div class="form-group">
        <button type="button" (click)="d('cancel')" class="btn btn-secondary btn-sm"
          style="margin-left: 10px;">{{'cancel'|translate}}
        </button>
      </div>
    </div>
  </ng-template>
</div>
