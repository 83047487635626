import {Component, OnInit} from '@angular/core';
import Auth from '@aws-amplify/auth/';
import {AuthService} from '@app/components/auth/auth.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.css']
})
export class SignOutComponent implements OnInit {

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
  }

  signOut(): void {
    console.log('signOut started.');    
    this.authService.signOut();
    /*Auth.signOut()
      .then(() => {
        this.authService.setAppUser(false, null, null, null, null, null);
        return true;
      })
      .catch(err => {
        this.authService.setAppUser(false, null, null, null, null, null);
        console.log(err);
        return false;
      });*/    
  }
}
